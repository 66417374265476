import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Three from '../scripts/three';

import styled from 'styled-components';

const Title = styled.h1`
  font-size: 4rem;
  color: #fff;
`;

const Heading = styled.h3`
  font-size: 2rem;
  color: #fff;
  padding-top: 4rem;
  margin-bottom: -2rem;
`;

export default function Enter() {
  const history = useHistory();

  const [loadingAnimation, setLoadingAnimation] = useState(true);

  console.log('loadingAnimation', loadingAnimation);
  console.log('localStorage.animation', localStorage.animation);

  const checkForAnimation = () => {
    const canvas = document.getElementById('canvas');
    const loader = document.getElementById('loader');
    // console.log('loader', loader);
    console.log('localStorage.animation', localStorage.animation);

    loader.classList.add('visible');

    setTimeout(() => {
      if (localStorage.animation === 'false') {
        loader.classList.add('hidden');
      }
    }, 2000);

    if (localStorage.animation === 'true') {
      loader.classList.remove('visible');
      loader.classList.add('hidden');
    }

  };

  const handleButtonClick = (value) => {
    if (value === 'enter') {
      history.push('/home');
      history.go('/home');
    }
    if (value === 'signin') {
      history.push('/signin');
      history.go('/signin');
    }
  };

  useEffect(() => {
    checkForAnimation();
  }, [loadingAnimation]);

  return (
    <div className="App">
      <Title>Rock Solid Skulls</Title>

      <div className="btn-enter">
        <style type="text/css">
          {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      box-shadow:none;
      z-index: 1;
      margin-top: 25vh;
      margin-bottom:1vh;
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem 2.5rem;
      font-size: 1.5rem;

    }

    `}
        </style>
        <Button
          variant="flat"
          size="lg"
          value="enter"
          onClick={() => handleButtonClick('enter')}
        >
          Enter
        </Button>{' '}
        <Button
          variant="flat"
          size="lg"
          value="signin"
          onClick={() => handleButtonClick('signin')}
        >
          Sign in
        </Button>
      </div>
      <div>
        <Heading id="" className="loader">
          {/* <h2>Loading...</h2> */}
        </Heading>
      </div>
      {loadingAnimation && <div id="preloader">
          <div id="loader"></div>
      </div>}
      <div className="canvas-container">
        <div id="canvas"></div>
      </div>
    </div>
  );
}
