import styled from 'styled-components';
import Layout from '../components/Layout';
import SignUpForm from '../components/signup/SignUpForm';

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

export default function SignUp() {
  return (
    <>
      <Layout>
        <>
          <Title>Sign up</Title>
          <SignUpForm></SignUpForm>
        </>
      </Layout>
    </>
  );
}
