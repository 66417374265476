import styled from 'styled-components';

const Card = styled.div`
  /* background: linear-gradient(to bottom, rgba(255, 255, 255, .1), var(--purple) ); */
  /* border: 3px solid var(--black); */
  background-color: transparent !important;
  color: #fff !important;
  /* background-color: var(--white); */
  /* border: solid 1px var(--white); */
  border-radius: 10px;
  filter: drop-shadow(0px 4px 18px rgba(255, 255, 255, 0.1));
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 1.5rem;
  overflow: hidden;
  width: 100%;
  height: 100%;

  transition: transform 0.6s ease;
  transform-style: preserve-3d;

  &:hover {
    filter: drop-shadow(0px 4px 18px rgba(255, 255, 255, 0.2));
  }

  img {
    height: auto;
    max-width: 100%;
    border-radius: 10px;
    border: solid 1px #000;
  }

  h3 {
    flex-direction: column;
    text-align: center;
    font-weight: bold;
    max-height: 2rem;
    overflow: scroll;
    padding-top: 0.5rem;
  }

  p {
    font-weight: 400;
    line-height: 1.5;
    margin-bottom: 1rem;
    padding: 1rem 1rem;
    overflow: scroll;

    height: 150px;
    font-size: 1.2rem;
  }

  .card-content {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }

  .card-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: var(--grey); */
    padding: 0.5rem 1rem;
    margin-top: auto;

    span {
      font-size: 1rem;
      font-weight: 400;
      color: var(--purple);
    }

    button {
      background-color: var(--purple);
      border: none;
      border-radius: 4px;
      color: #fff;
      cursor: pointer;
      font-size: 1rem;
      font-weight: 600;
      padding: 0.5rem 1rem;
      box-shadow: none;

      &:hover {
        background-color: var(--green);
      }
    }
  }
`;

export default Card;
