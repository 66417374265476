import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Card from '../../components/Card';
import CardFront from '../../components/CardFront';
import CardBack from '../../components/CardBack';
import Modal from '../../components/Modal';
import heartEmpty from '../../img/heart-empty.svg';
import heartFilled from '../../img/heart-filled.svg';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  max-width: 900px;
  margin: 4rem auto;

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

const Container = styled.div`
  margin: auto 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const HeartIcon = styled.img`
  display: flex;
  align-self: center;
  height: 25px;
  width: 25px;
  margin: auto;
  padding-top: 1rem;
  cursor: pointer;
`;

const TwoColumnCards = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const [favorites, setFavorites] = useState([]);
  const [heartFilledColor, setHeartFilledColor] = useState([]);
  let token = null;
 

  //fetch all favorites from db with current user token
  useEffect(() => {
    const fetchHearts = async () => {
      const token = localStorage.getItem('token');
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };
      if (token === null) {
        return;
      }
      const res = await fetch(`${BASE_URL}/hearts/`, requestOptions);
      const data = await res.json();

      setFavorites(data);
    };

    fetchHearts();
  }, []);

  const handleButtonClick = (id) => {
    const cardFront = document.getElementById(`cardFront-${id}`);
    const cardBack = document.getElementById(`cardBack-${id}`);
    cardFront.classList.toggle('hidden');
    cardFront.classList.toggle('show');
    cardBack.classList.toggle('hidden');
    cardBack.classList.toggle('show');
  };

  // Add to Cart flow

  // const [showModal, setShowModal] = useState(false);
  const [showModal, setShowModal] = useState(
    Array(favorites.length).fill(false)
  );
  const [itemId, setItemId] = useState('');

  // console.log('outside', itemId);

  // Modal confirmation messages
  const title = 'Confirm';
  const message = 'Are you sure?';

  // includes logic to sort the correct card itemId
  const addToCart = (id) => {
    const index = favorites.findIndex((favorite) => favorite.id === id);
    if (index !== -1) {
      const newShowModal = [...showModal];
      newShowModal[index] = true;
      setShowModal(newShowModal);
    }
  };

  // Submit item id to POST in cart

  useEffect(() => {
    const addItemToCart = async () => {
      const token = localStorage.getItem('token');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ itemId })
      };

      // console.log(requestOptions);
      try {
        const res = await fetch(
          `${BASE_URL}/cart/add`,
          requestOptions
        );
        if (res.status === 400) {
          console.log('You already added this to your cart');
        } else {
          // console.log('POST request successful:', res);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (itemId) {
      addItemToCart();
    }
  }, [itemId]);

  const handleConfirm = (id) => {
    const index = favorites.findIndex((favorite) => favorite.id === id);
    if (index !== -1) {
      const newShowModal = [...showModal];
      newShowModal[index] = false;
      setShowModal(newShowModal);

      // Trigger the event for adding to cart
      setItemId(id);
    }
  };

  const handleCancel = (id) => {
    const index = favorites.findIndex((favorite) => favorite.id === id);
    if (index !== -1) {
      const newShowModal = [...showModal];
      newShowModal[index] = false;
      setShowModal(newShowModal);
      // Handle cancellation
    }
  };

  // updates color but does not DELETE
  // const handleHeartClick = (id) => {
  //   setHeartFilledColor((prevColors) => {
  //     const updatedColors = [...prevColors];
  //     const index = updatedColors.findIndex((color) => color.id === id);

  //     if (index !== -1) {
  //       updatedColors[index].filled = !updatedColors[index].filled;
  //     }

  //     // delete heart when filled on click

  //     return updatedColors;
  //   });
  // };

  useEffect(() => {
    setHeartFilledColor(
      favorites.map((favorite) => ({ id: favorite.id, filled: true }))
    );
  }, [favorites]);

  // working function that deletes on the first click
  const handleHeartClick = async (id) => {
    // console.log('heartFilledColor:', heartFilledColor);
    // console.log('favorites:', favorites);

    setHeartFilledColor((prevColors) => {
      const updatedColors = [...prevColors];
      const index = updatedColors.findIndex((color) => color.id === id);

      if (index !== -1) {
        updatedColors[index].filled = !updatedColors[index].filled;

        if (!updatedColors[index].filled) {
          deleteFavorite(id);
        }
      }

      return updatedColors;
    });
  };

  const deleteFavorite = async (id) => {
    try {
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      };

      const response = await fetch(
        `${BASE_URL}/heart/delete/${id}`,
        requestOptions
      );

      if (response.ok) {
        // console.log(response);

        // Remove the deleted item from the favorites state
        setFavorites((prevFavorites) => {
          const updatedFavorites = prevFavorites.filter(
            (favorite) => favorite.id !== id
          );
          return updatedFavorites;
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Wrapper>
      <Container>
        {favorites.map((favorite, index) => (
          <Card key={favorite.id}>
            <CardFront className="show" id={`cardFront-${favorite.id}`}>
              <img
                src={`${BASE_URL}/uploads/${favorite.photo}`}
                alt={favorite.name}
              />
              <div className="card-body">
                <h3>{favorite.name}</h3>
              </div>
              <div className="card-footer">
                <button onClick={() => handleButtonClick(favorite.id)}>
                  Details
                </button>
              </div>
              <HeartIcon
                className="heart-icon"
                src={
                  heartFilledColor.find((color) => color.id === favorite.id)
                    ?.filled
                    ? heartFilled
                    : heartEmpty
                }
                alt="Heart"
                onClick={() => handleHeartClick(favorite.id)}
              />
            </CardFront>
            <CardBack className="hidden" id={`cardBack-${favorite.id}`}>
              <div className="card-body">
                {/* <h3>{favorite.location.address}</h3> */}
                <p>{favorite.description}</p>
              </div>
              <div className="item-price">${favorite.price}</div>
              <div className="card-footer">
                <button onClick={() => addToCart(favorite.id)}>Add</button>
                {showModal[index] && (
                  <Modal
                    onConfirm={() => handleConfirm(favorite.id)}
                    onCancel={() => handleCancel(favorite.id)}
                    title={title}
                    message={message}
                  >
                    <button onClick={() => handleConfirm(favorite.id)}>
                      Yes
                    </button>
                    <button onClick={() => handleCancel(favorite.id)}>
                      No
                    </button>
                  </Modal>
                )}

                <div className="btn-spacer"> </div>
                <button onClick={() => handleButtonClick(favorite.id)}>
                  Back
                </button>
              </div>
            </CardBack>
          </Card>
        ))}
      </Container>
    </Wrapper>
  );
};

export default TwoColumnCards;
