import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import aboutPic from '../../img/about-owner.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 4rem 0 -4rem 0;
  /* padding-top: 20rem; */
  position: relative;
  height: 100%;
  overflow: hidden;
  @media only screen and (max-width: 696px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 50vh;
  margin-left: 2rem;

  @media screen and (max-width: 696px) {
    width: 100%;
    height: 15vh;
    padding: 0 4rem;
  }
`;

const RightColumn = styled.div`
  width: 50%;
  height: 50%;
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 0 3rem;
  margin-top: 8rem;

  @media screen and (max-width: 4800px) and (min-width: 696px) {
    margin-top: -5rem;
  }

  @media screen and (max-width: 696px) {
    width: 100%;
    margin-top: 4;
    padding: 0 4rem;
  }

  @media screen and (max-width: 389px) {
    margin-top: 12rem;
  }
`;

const Heading = styled.h2`
  font-size: 3rem;
  margin-left: 20%;
  margin-top: 4rem;
  @media screen and (max-width: 696px) {
    margin: 0;
  }

  @media screen and (max-width: 360px) {
    font-size: 2.8rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;

  padding: 0 6rem;
  color: var(--white);
  @media screen and (max-width: 696px) {
    padding: 7rem 4rem 2rem;
  }
`;
const ColumnFullWidth = styled.div`
  width: 100%;

  color: var(--white);
  @media screen and (max-width: 696px) {
  }
`;

const ImageCarousel = styled.div`
  display: flex;
  padding-bottom: 1rem;

  @media screen and (max-width: 389px) {
    padding-bottom: 0;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin-right: 1rem;

  object-fit: cover;
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  padding-left: 6rem;
  @media screen and (max-width: 696px) {
    padding-left: 4rem;
  }
`;

const TwoColumnImageRightSecond = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/contact');
    history.go('/contact');
  };

  return (
    <>
      <Wrapper>
        <LeftColumn>
          <Heading>Meet the Mind Behind Rock Solid Skulls</Heading>
        </LeftColumn>
        <RightColumn>
          <ImageCarousel>
            <Image src={aboutPic} />
          </ImageCarousel>
        </RightColumn>
      </Wrapper>
      <ColumnFullWidth>
        <Paragraph>
          Madeline discovered the healing power of crystal skulls through her
          own personal journey. <br></br> <br></br> Her love for crystal skulls
          has inspired her to provide resources and expert knowledge to help
          others embrace their potential for transformation and growth.
        </Paragraph>
        <BtnContainer className="about-button">
          <style type="text/css">
            {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    width: 12rem;
                    }

                    `}
          </style>
          <Button variant="flat" size="xxl" onClick={handleButtonClick}>
            Contact
          </Button>
        </BtnContainer>
      </ColumnFullWidth>
    </>
  );
};

export default TwoColumnImageRightSecond;
