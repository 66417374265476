import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import ShoppingCartIcon from './nav/ShoppingCartIcon';
import heart from '../img/heart.svg';
import AI_HANDLER from '../components/chat/AI'

const FooterStyles = styled.nav`
  footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    place-items: center;

    padding-bottom: 1rem;
  }
`;

const NavStyles = styled.nav`
  background-color: var(--black);

  .nav-container {
    margin: 2rem auto 0 auto;
    padding-top: 0rem;
    text-align: center;
    line-height: 2;
    display: flex;
    flex-direction: row;

    justify-content: space-evenly;
    max-width: 696px;
    grid-gap: 1rem;
    font-size: 1rem;
    @media (max-width: 425px) {
      padding: 0 2rem;
      justify-content: center;
      grid-gap: 0.5rem;
    }
  }

  a {
    font-size: 1rem;
    text-decoration: none;
    display: block;
    color: var(--white);

    &:hover {
      color: var(--green);
    }

    @media (max-width: 800px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 4800px) {
    --columns: 4;
    margin-top: 4rem;
    margin-bottom: 2rem;
    border-top: 2px solid var(--grey);
    border-bottom: 2px solid var(--grey);
    padding-bottom: 2rem;
  }

  @media (max-width: 500px) {
    --columns: 2;
  }
`;

const HeartIcon = styled.img`
  height: 20px;
  width: 20px;
`;

export default function Footer() {
  return (
    <>
      <NavStyles>
        <div className="nav-container">
          <div>
            <NavLink to="/home">Home</NavLink>

            <NavLink to="/about">About</NavLink>
            <NavLink to="/learn">Learn</NavLink>
          </div>

          <div>
            <NavLink to="/learn/faq">FAQ</NavLink>

            <NavLink to="/learn/videos">Videos</NavLink>
            <NavLink to="/learn/library">Library</NavLink>
          </div>

          <div>
            <NavLink to="/shop">Shop</NavLink>

            <NavLink to="/shop/skulls">Skulls</NavLink>

            <NavLink to="/shop/jewelry">Jewelry</NavLink>
          </div>
          <div>
            <NavLink to="/blog">Blog</NavLink>

            <NavLink to="/contact">Contact</NavLink>
            <NavLink to="/favorites">
              <HeartIcon src={heart} alt="Heart" />
            </NavLink>
          </div>
          <div>
            {!localStorage.token && (
              <div>
                <NavLink to="/signin">Sign in</NavLink>
              </div>
            )}
            {localStorage.token && (
              <div>
                <NavLink to="/signout">Sign out</NavLink>
              </div>
            )}
            <NavLink to="/account">Account</NavLink>
            <NavLink to="/cart">
              <ShoppingCartIcon></ShoppingCartIcon>
            </NavLink>
          </div>
        </div>
      </NavStyles>
      <FooterStyles>
        <footer>
          <AI_HANDLER></AI_HANDLER>
          <h6>&copy; Rock Solid Skulls 💀 {new Date().getFullYear()}</h6>
        </footer>
      </FooterStyles>
    </>
  );
}
