import styled from 'styled-components';
import heroBackground from '../../public/img/gem-bg.png';

const Hero = styled.div`
  .hero-container {
    position: relative;
    height: 75vh;
    width: 100vw;
    overflow: hidden;
    background-image: url(${heroBackground});
    min-height: 500px;

    /* parallax */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    /* adjustments for screens under 768px */
    @media (max-width: 767px) {
      background-attachment: scroll; /* disable parallax */
      background-position: center top;
      background-size: auto; /* adjust as needed */
    }
  }
`;

const HeroSection = () => {
  return (
    <Hero>
      <div className="hero-container"></div>
    </Hero>
  );
};

export default HeroSection;
