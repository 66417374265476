import Layout from '../components/Layout';
import UpdateAccountForm from '../components/account/UpdateAccountForm.js';

export default function Account() {
  return (
    <>
      <Layout>
        <>
          <UpdateAccountForm></UpdateAccountForm>
        </>
      </Layout>
    </>
  );
}
