import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import logoMain from '../../img/logo-main.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  position: relative;
  height: 100vh;
  overflow: hidden;
  
  @media screen and (min-width: 696px) {
    flex-direction: row;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 2rem;
  margin-top: -18rem;
  
  @media screen and (max-width: 696px) {
    margin-top: 2rem;
    margin-left: 0;
    place-items: center;
    width: 100%;
  }
`;

const RightColumn = styled.div`
  width: 50%;
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 0 3rem;
  
  @media screen and (max-width: 696px) {
    padding: 0;
    margin-left: 0;
    width: 18rem;
  }
`;

const Heading = styled.h1`
  font-size: 6rem;
  margin-left: 20%;
  margin-top: 2rem;
  
  @media screen and (max-width: 696px) {
    margin-left: 0;
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  color: var(--white);
`;

const ImageCarousel = styled.div`
  display: flex;
  
  @media screen and (max-width: 696px) {
    flex-direction: column;
  }
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin-right: 1rem;
  margin-top: -12rem;
  object-fit: cover;
  
  @media screen and (max-width: 696px) {
    width: 20rem;
    margin-top: -30rem;
    margin-right: 0;
  }
`;


const TwoColumnImageRight = () => {
  return (
    <Wrapper>
      <LeftColumn>
        <Heading>Our Story</Heading>
      </LeftColumn>
      <RightColumn>
        <ImageCarousel>
          <Image src={logoMain} />
        </ImageCarousel>
      </RightColumn>
    </Wrapper>
  );
};

export default TwoColumnImageRight;
