import Layout from '../components/Layout';
import FaqTemplate from '../components/faq/FaqTemplate';

export default function Faq() {
  const faqData = [
    {
      id: 1,
      question: 'What is your return policy?',
      answer:
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed et velit a dolor varius elementum vitae a libero. Duis malesuada sollicitudin enim, vel sagittis lacus accumsan sit amet. '
    },
    {
      id: 2,
      question: 'How can I track my order?',
      answer:
        'Pellentesque euismod eu nisl eget egestas. Nullam non urna velit. Nullam malesuada turpis non neque tristique posuere.'
    },
    {
      id: 3,
      question: 'Can I cancel my order?',
      answer:
        'Aenean iaculis luctus mauris id eleifend. Duis commodo lobortis mi, eu dapibus purus efficitur vel.'
    },
    {
      id: 4,
      question: 'How do I return a product?',
      answer:
        'Vivamus malesuada nisl at sapien hendrerit, sit amet volutpat augue iaculis. Nulla auctor, sapien id pretium congue, mi ante tristique nulla, nec sagittis urna neque in sapien.'
    },
    {
      id: 5,
      question: 'What payment methods do you accept?',
      answer:
        'Maecenas vitae eros massa. Integer vel odio aliquet, finibus est id, sollicitudin ipsum.'
    }
  ];

  return (
    <>
      <Layout>
        <>
          <FaqTemplate faqData={faqData}></FaqTemplate>
        </>
      </Layout>
    </>
  );
}
