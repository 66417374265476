import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const FormWrapper = styled.div`
  max-width: 400px;
  padding: 20px;
  margin: auto;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;

  h3 {
    margin-top: 0;
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
`;

const SignOutForm = () => {
  
  const history = useHistory();

  const handleSignOutClick = (value) => {
    localStorage.removeItem('token');
    console.log('User signed out successfully');
    if (value === 'yes') {
      history.push('/home');
      history.go('/home');
    }
  };
  const handleSignOutClickNo = (value) => {
    if (value === 'no') {
      history.push('/home');
      history.go('/home');
    }
  };

  return (
    <>
      <FormWrapper>
        <div>
          <h3>Are you sure?</h3>
          <div className="btn-form">
            <style type="text/css">
              {`
    .btn-flat {
      background-color: var(--green);
      color: white;
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem;
      font-size: 1.5rem;
      box-shadow: none;
    }

    `}
            </style>

            <Button
              value="yes"
              variant="flat"
              size="xxl"
              onClick={() => handleSignOutClick('yes')}
            >
              Yes
            </Button>
            <Button
              value="no"
              variant="flat"
              size="xxl"
              onClick={() => handleSignOutClickNo('no')}
            >
              No
            </Button>
          </div>
        </div>
      </FormWrapper>
    </>
  );
};

export default SignOutForm;
