import React from 'react';
import styled from 'styled-components';


const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 20px;
`;

const ModalContent = styled.div`
  max-width: 400px;
  padding: 12px;
  border-radius: 20px;
  background-color: transparent;
  
  text-align: center;
`;

const ModalTitle = styled.h2`
  margin-top: 0;
  font-size: 24px;
  color: var(--white);
`;

const ModalMessage = styled.p`
  font-size: 18px;
  color: var(--white);
  height: 100px !important;
`;

const ModalButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const ModalButton = styled.button`
  margin: 0 10px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  color: #fff;
  background-color: ${({ confirm }) => (confirm ? '#5cb85c' : '#d9534f')};
`;

const Modal = ({ title, message, onConfirm, onCancel }) => {
  return (
    <ModalWrapper>
      <ModalContent>
        <ModalTitle>{title}</ModalTitle>
        <ModalMessage>{message}</ModalMessage>
        <ModalButtonWrapper>
          <ModalButton confirm onClick={onConfirm}>
            Yes
          </ModalButton>
          <ModalButton onClick={onCancel}>No</ModalButton>
        </ModalButtonWrapper>
      </ModalContent>
    </ModalWrapper>
  );
};

export default Modal;
