import styled from 'styled-components';
import Layout from '../components/Layout';
import ResetPasswordForm from '../components/reset/ResetPasswordForm';

const Title = styled.h1`
  font-size: 6rem;

  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

export default function ResetPassword() {
  return (
    <>
      <Layout>
        <>
          <Title>Reset Password</Title>
          <ResetPasswordForm></ResetPasswordForm>
        </>
      </Layout>
    </>
  );
}
