import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const FormWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;

  h2 {
    margin-top: 0;
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  p {
    font-size: 18px;
    color: #555;
    text-align: center;
  }

  button {
  }
`;

const LinkText = styled.a`
  font-size: 1rem;
  font-weight: 800;
  cursor: pointer;
  color: #000;
`;

const buttonHoverStyles = styled.div`
  background-color: var(--red);
  border: 1px solid var(--red);
`;

const StyledButton = styled.button`
  position: relative;
  background-color: transparent;
  color: var(--green);
  border: 2px solid var(--green);
  padding: 0.5 1rem;
  font-size: 1.5rem;
  box-shadow: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  transition: background-color 0.5s;

  &:hover {
    background-color: var(--red);
    color: white;
  }

  &:hover::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    background: linear-gradient(to top, var(--red), transparent);
    z-index: -1;
    transition: background-color 0.3s;
    transition: fade in 0.3s;
  }
`;

const SignUpForm = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [honeypot, setHoneypot] = useState('');

  const [confirmPassword, setConfirmPassword] = useState('');
  const [signupConfirm, setSignupResetConfirm] = useState('');
  const history = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // check for honeypot
    if (honeypot) {
      return;
    }

    const formData = {
      email,
      name,
      password,
      confirmPassword
    };

    // console.log(formData);

    if (formData.email === '') {
      setSignupResetConfirm('Must provide an email address');
      return;
    }
    if (formData.name === '') {
      setSignupResetConfirm('Must provide a name');
      return;
    }
    if (formData.password !== formData.confirmPassword) {
      setSignupResetConfirm('Passwords must match');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/api/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!response.ok) {
        throw new Error('Failed to register');
      }

      const data = await response.json();
      // JWT token from response
      localStorage.setItem('token', data.token);
      // console.log('returned server jwt token', data.token);
      // console.log('localStorage', localStorage);
      const localSessionId = localStorage.getItem('token');
      // console.log('localSessionId', localSessionId);
      if (localSessionId) {
        setSignupResetConfirm('Account created successfully');
        setTimeout(() => {
          history.push('/home');
          history.go('/home');
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleButtonClick = (value) => {
    if (value === 'enter') {
      history.push('/home');
      history.go('/home');
    }
    if (value === 'back') {
      history.push('/signin');
      history.go('/signin');
    }
  };

  return (
    <>
      <FormWrapper>
        <Form onSubmit={handleSubmit}>
          <div>
            <Form.Label htmlFor="email"></Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="email"
              maxLength={50}
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <Form.Label htmlFor="name"></Form.Label>
            <Form.Control
              type="text"
              name="text"
              placeholder="name"
              autoComplete="new-name"
              maxLength={20}
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <Form.Label htmlFor="password"></Form.Label>
            <Form.Control
              type="password"
              name="password"
              placeholder="password"
              maxLength={20}
              id="password"
              value={password}
              autoComplete="new-password"
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <Form.Label htmlFor="confirmPassword"></Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              autoComplete="new-password"
              placeholder="confirm password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <div>
              <Form.Label htmlFor="honeypot" style={{ display: 'none' }}>
                Leave this field empty:
              </Form.Label>
              <Form.Control
                type="text"
                name="honeypot"
                id="honeypot"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
                style={{ display: 'none' }}
              />
            </div>
          </div>
          {signupConfirm && (
            <div className="error-section">
              <h5>{signupConfirm}</h5>
            </div>
          )}
          <div className="btn-form">
            <style type="text/css">
              {`
    .btn-flat {
      background-color: var(--green);
      color: white;
       
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: .5rem 1rem;
      font-size: 1.5rem;
      box-shadow: none;
    }

    `}
            </style>
            <Button variant="flat" size="xxl" type="submit">
              Create
            </Button>{' '}
            <Button
              variant="flat"
              size="xxl"
              hover={buttonHoverStyles}
              value="back"
              onClick={() => handleButtonClick('back')}
            >
              Back
            </Button>
          </div>
        </Form>
      </FormWrapper>
    </>
  );
};

export default SignUpForm;
