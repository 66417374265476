import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Card from '../../components/Card';
import logoMain from '../../img/logo-main.png';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: auto;
  position: relative;
  overflow: hidden;
  max-width: 696px;

  @media (max-width: 550px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
  }
`;

const Column = styled.div`
  width: 50%;
  margin: 2rem;

  @media (max-width: 550px) {
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
    padding: 2rem;
  }
`;

const Heading = styled.h1`
  font-size: 3rem;
  margin-bottom: 2rem;

  @media (max-width: 550px) {
    font-size: 2rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  color: var(--black);
`;

const ImageCarousel = styled.div`
  display: flex;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin-right: 1rem;
  padding: 1rem;
  object-fit: cover;
`;

const TwoColumnCards = () => {
  const history = useHistory();

  const handleButtonClick = (value) => {
    if (value === 'skulls') {
      history.push('/shop/skulls');
      history.go('/shop/skulls');
    }
    if (value === 'jewelry') {
      history.push('/shop/jewelry');
      history.go('/shop/jewelry');
    }
  };

  return (
    <Wrapper>
      <Column>
        <Card>
          <Image src="https://jawaragordon.com/img/rss/12.png"></Image>

          <div className="shop-button">
            <style type="text/css">
              {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    }

                    `}
            </style>
          </div>
          <Button
            variant="flat"
            size="xxl"
            value="skulls"
            onClick={() => handleButtonClick('skulls')}
          >
            Skulls
          </Button>
        </Card>
      </Column>
      <Column>
        <Card>
          <Image src="https://jawaragordon.com/img/rss/crystal-bracelet.png"></Image>
          <div className="shop-button">
            <style type="text/css">
              {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    }

                    `}
            </style>
          </div>
          <Button
            variant="flat"
            size="xxl"
            value="jewelry"
            onClick={() => handleButtonClick('jewelry')}
          >
            Jewelry
          </Button>
        </Card>
      </Column>
    </Wrapper>
  );
};

export default TwoColumnCards;
