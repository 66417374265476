import React, { useEffect, useState } from 'react';
import Card from './Card';

import styled from 'styled-components';

const InfoCard = styled.div`
  max-width: 600px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;

  .image {
    width: 50%;
    margin: auto;
  }

  img {
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
  }

  .name {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
  }

  .description {
    font-size: 1.4rem;
    color: #666;
  }

  .location {
    font-size: 1.4rem;
    color: #666;
    font-style: italic;
  }

  .healing-property {
    font-size: 1.4rem;
    color: #333;
    font-weight: bold;
  }

  @media screen and (max-width: 696px) {
    font-size: 1.5rem;
    margin: auto;
    margin-bottom: 1rem;
    width: 100%;
  }

  @media screen and (max-width: 425px) {
    font-size: 1.5rem;
    margin: auto;
    margin-bottom: 1rem;
    max-width: 300px;

    .name {
      text-align: center;
      /* padding-right: 3rem; */
    }
    .description {
      text-align: center;
      /* padding-right: 3rem; */
    }

    .location {
      text-align: center;
      /* padding-right: 3rem; */
    }

    .healing-property {
      text-align: center;
      /* padding-right: 3rem; */
    }
  }
`;
const Container = styled.div`
  margin: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1rem;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 768px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0px;
  }
`;

const DropDownContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;
const ResetContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;

  button {
    max-width: 12rem;
   
    margin: .5rem;
    margin-bottom: 1rem;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 12rem;
  }
`;

const Dropdown = styled.select`
  width: 30%;
  padding: 0.5rem 1rem;
  font-size: 1.4rem;
  border: none;
  border-radius: 4px;
  background-color: #f5f5f5;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  margin: auto;
  margin-bottom: 2rem;
  max-width: 400px; /* Added max-width property */

  &:hover {
    background-color: #ececec;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }

  option {
    background-color: #fff;
    color: #333;
    transition: background-color 0.3s ease-in-out;
  }

  option:hover {
    background-color: #ececec;
  }

  @media screen and (max-width: 696px) {
    font-size: 1.5rem;
    margin: auto;
    margin-bottom: 1rem;
    max-width: 250px;
  }

  button {
    margin-left: 0.5rem;
  }
`;

const FilterColumn = ({ onFilterData }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [filteredInfo, setFilteredInfo] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [propertyNames, setPropertyNames] = useState(['']);
  const [selectedLocation, setSelectedLocation] = useState('');
  const [locationNames, setLocationNames] = useState(['']);
  const [selectedStoneName, setSelectedStoneName] = useState('');
  const [stoneNames, setStoneNames] = useState(['']);

  const [info, setInfo] = useState([]);

  // console.log('info', info);
  useEffect(() => {
    fetchInfo();
  }, []);

  const fetchInfo = async () => {
    try {
      const response = await fetch(`${BASE_URL}/learn/info`);
      const data = await response.json();
      // console.log(data);

      setInfo(data);

      const allNames = data.flatMap((stone) => stone.name);
      const uniqueName = [...new Set(allNames)];
      const uniqueNamesSorted = uniqueName.sort();

      const allProperties = data.flatMap((stone) => stone.healing_property);
      const uniqueProperties = [...new Set(allProperties)];
      const uniquePropertiesSorted = uniqueProperties.sort();

      const allLocations = data.flatMap((stone) => stone.location);
      const uniqueLocations = [...new Set(allLocations)];
      const uniqueLocationsSorted = uniqueLocations.sort();

      setStoneNames(uniqueNamesSorted);
      setPropertyNames(uniquePropertiesSorted);
      setLocationNames(uniqueLocationsSorted);
      onFilterData(data);
    } catch (error) {
      console.error('Error fetching info:', error);
    }
  };

  const handleNameChange = (event) => {
    setSelectedStoneName(event.target.value);
  };

  const handlePropertyChange = (event) => {
    setSelectedProperty(event.target.value);
  };

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  useEffect(() => {
    const filterInfo = (
      info,
      selectedStoneName,
      selectedProperty,
      selectedLocation
    ) => {
      let filteredInfo = info;

      if (selectedStoneName !== '' || selectedLocation === '') {
        filteredInfo = filteredInfo.filter((item) =>
          item.name.toLowerCase().includes(selectedStoneName.toLowerCase())
        );
      }

      if (selectedProperty !== '' || selectedLocation === '') {
        filteredInfo = filteredInfo.filter((item) =>
          item.healing_property
            .toLowerCase()
            .includes(selectedProperty.toLowerCase())
        );
      }

      if (selectedLocation !== '' || selectedProperty === '') {
        filteredInfo = filteredInfo.filter((item) =>
          item.location.toLowerCase().includes(selectedLocation.toLowerCase())
        );
      }

      return filteredInfo;
    };

    const filteredInfo = filterInfo(
      info,
      selectedStoneName,
      selectedProperty,
      selectedLocation
    );
    setFilteredInfo(filteredInfo);
  }, [info, selectedStoneName, selectedProperty, selectedLocation]);

  // Sort filteredInfo array alphabetically by name
  const sortedFilteredInfo = [...filteredInfo].sort((a, b) =>
    a.name.localeCompare(b.name)
  );

  const handleNameReset = () => {
    setSelectedStoneName('');
  };

  const handlePropertyReset = () => {
    setSelectedProperty('');
  };

  const handleLocationReset = () => {
    setSelectedLocation('');
  };

  return (
    <>
      <DropDownContainer>
        <Dropdown value={selectedStoneName} onChange={handleNameChange}>
          <option value="">Filter by Name</option>
          <option value="">Reset</option>
          {stoneNames.map((tagName) => (
            <option key={tagName} value={tagName}>
              {tagName}
            </option>
          ))}
        </Dropdown>

        <Dropdown value={selectedProperty} onChange={handlePropertyChange}>
          <option value="">Filter by Property</option>
          <option value="">Reset</option>
          {propertyNames.map((tagName) => (
            <option key={tagName} value={tagName}>
              {tagName}
            </option>
          ))}
        </Dropdown>

        <Dropdown value={selectedLocation} onChange={handleLocationChange}>
          <option value="">Filter by Location</option>
          <option value="">Reset</option>
          {locationNames.map((tagName) => (
            <option key={tagName} value={tagName}>
              {tagName}
            </option>
          ))}
        </Dropdown>
      </DropDownContainer>
      <ResetContainer>
        {selectedStoneName && (
          <button onClick={handleNameReset}>Reset Name</button>
        )}
        {selectedProperty && (
          <button onClick={handlePropertyReset}>Reset Property</button>
        )}
        {selectedLocation && (
          <button onClick={handleLocationReset}>Reset Location</button>
        )}
      </ResetContainer>
      <Container>
        {sortedFilteredInfo.map((stone, index) => (
          <React.Fragment key={stone._id}>
            <Card>
              <InfoCard className="info-card">
                <div className="image">
                  <img src={stone.image} alt={stone.name} />
                </div>
                <div className="name">{stone.name}</div>
                <div className="description">{stone.description}</div>
                <div className="location">{stone.location}</div>
                <div className="healing-property">{stone.healing_property}</div>
              </InfoCard>
            </Card>
          </React.Fragment>
        ))}
      </Container>
    </>
  );
};

export default FilterColumn;
