import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-scroll';
import heroBackground from '../../public/img/rss-home-banner.jpg';

const Hero = styled.div`
  .hero-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 100vh;
    overflow: hidden;

    z-index: 0;
    background-image: url(${heroBackground});
    background-size: cover;
    background-position: center center;

    clip-path: polygon(
      0% 0%,
      100% 0%,
      100% 80%,
      75% 85%,
      50% 80%,
      25% 85%,
      0% 80%
    );
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    place-items: center;
    margin-top: -20rem;
    padding: 0 2rem;
    @media (max-width: 600px) {
      padding-bottom: 2rem;
    }
  }

  .hero-heading {
    font-size: 8rem;
    color: #fff;
    margin: 2rem auto 2rem auto;

    @media screen and (min-width: 900px), screen and (max-height: 390px) {
      margin-top: 6rem;
      margin-bottom: 1rem;
      font-size: 7rem;
    }
    @media (max-width: 600px) {
      font-size: 6rem;
    }
    @media (max-width: 375px) {
      margin: 4rem auto 2rem auto;
    }
  }

  .hero-button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    @media screen and (min-width: 900px), screen and (max-height: 390px) {
      margin-top: 1rem;
    }
  }

  .hero-button:hover {
    background-color: #000;
    color: #fff;
  }
`;

const HeroSection = () => {
  return (
    <Hero>
      <div className="hero-container">
        <div className="hero-content">
          <h1 className="hero-heading">Rock Solid Skulls</h1>
          <div className="hero-button">
            <style type="text/css">
              {`
                  .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                    
                  }

                  .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                  }
                  .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    box-shadow: none;
                              }

              `}
            </style>
          </div>
          <Link to="get-started" smooth={true} duration={500}>
            <Button variant="flat" size="xxl">
              Get Started
            </Button>
          </Link>
        </div>
      </div>
    </Hero>
  );
};

export default HeroSection;
