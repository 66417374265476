import styled from 'styled-components';

const Card = styled.div`
  background-color: var(--purple);
  font-family: 'Montserrat';
  border-radius: 10px;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 2fr));
  gap: 2px;
  max-width: 1200px;
  margin: 0 auto;
  margin-bottom: 1rem;
  padding: 1rem;
  overflow: hidden;
  max-width: 600px;

  transition: transform 0.6s ease;
  transform-style: preserve-3d;

  &:hover {
    filter: drop-shadow(0px 4px 18px rgba(255, 255, 255, 0.6));
  }
`;

export default Card;
