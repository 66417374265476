import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  position: relative;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 768px) {
  }
  @media (max-width: 700px) {
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    /* padding: 20% 0; */

    /* margin-bottom: 10rem; */
    height: 100%;
    width: 100vw;
    
  }

`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 2rem;
  margin-top: -18rem;

  @media (max-width: 768px) {
    margin-top: -14rem;
  }
  @media (max-width: 700px) {
    margin-top: -12rem;
  }
  @media (max-width: 600px) {
    margin-top: 1rem;
    width: 75%;
  }
`;

const RightColumn = styled.div`
  width: 50%;
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 0 3rem;
  @media (max-width: 600px) {
    
    width: 100%;
  }
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  color: var(--white);
`;

const ImageCarousel = styled.div`
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 16px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--red);
    border-radius: 1px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 400px;
  height: 300px;
  margin-right: 1rem;
  object-fit: cover;
`;

const Btn = styled.button`
  .shop-button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .shop-button:hover {
    background-color: #000;
    color: #fff;
  }
`;

const TwoColumnImageRight = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/shop/');
    history.go('/shop/');
  };

  return (
    <Wrapper>
      <LeftColumn>
        <Heading>Featured Skulls </Heading>
        <Paragraph>
          Discover our handpicked selection of exquisite crystal skulls and
          jewelry. Each item has been carefully sourced and chosen for its
          quality and authenticity. Our range includes unique designs that are
          perfect for enhancing meditation, spiritual growth, and healing.
        </Paragraph>
      </LeftColumn>
      <RightColumn>
        <ImageCarousel>
          <Image src="https://jawaragordon.com/img/rss/12.png" />
          <Image src="https://jawaragordon.com/img/rss/13.png" />
          <Image src="https://jawaragordon.com/img/rss/rss-product-example-hero.png" />
        </ImageCarousel>
        <Paragraph>
          Whether you're a collector or simply looking for a meaningful gift,
          our products offer something for everyone. Shop now and experience the
          transformative power of these life-changing collectibles.
        </Paragraph>

        <div className="shop-button">
          <style type="text/css">
            {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    }

                    `}
          </style>
        </div>
        <Button variant="flat" size="xxl" onClick={handleButtonClick}>
          Shop Now
        </Button>
      </RightColumn>
    </Wrapper>
  );
};

export default TwoColumnImageRight;
