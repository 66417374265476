import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
// import loadingSkull from '../../img/skull-loading.gif';
import Spinner from '../utils/Spinner';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
`;

const VideoWrapper = styled.div`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 aspect ratio */
  height: 0;
`;

const Video = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

const Text = styled.h2`
  font-size: 3rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
`;
const Image = styled.img`
  display: flex;
  margin: auto;
  max-width: 60%;
  height: 60%;
  object-fit: cover;
  padding: 0 20% 2rem;
`;

const LoadingAnimation = () => {
  return (
    <LoadingContainer>
      <Spinner />
      <Text>Loading...</Text>
    </LoadingContainer>
  );
};

const videos = [
  {
    id: 1,
    src: 'https://www.youtube.com/embed/4RbuJNFNins',
    title: 'YouTube video player'
  },
  {
    id: 2,
    src: 'https://www.youtube.com/embed/HeiMfLmJtzk',
    title: 'YouTube video player'
  },
  {
    id: 3,
    src: 'https://www.youtube.com/embed/gjJk4JutS-c',
    title: 'YouTube video player'
  },
  {
    id: 4,
    src: 'https://www.youtube.com/embed/cPbGoSkObVQ',
    title: 'YouTube video player'
  }
];

const VideoGrid = () => {
  const [loading, setLoading] = useState(true);
  const [loadedVideos, setLoadedVideos] = useState(0);

  useEffect(() => {
    if (loadedVideos === videos.length) {
      setLoading(false);
    }
  }, [loadedVideos]);

  const handleVideoLoad = () => {
    setLoadedVideos((prevCount) => prevCount + 1);
  };

  return (
    <>
      <Title>Videos</Title>
      {loading && <LoadingAnimation />}
      <Wrapper>
        {videos.map((video) => (
          <VideoWrapper key={video.id}>
            <Video
              src={video.src}
              title={video.title}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              onLoad={handleVideoLoad}
            />
          </VideoWrapper>
        ))}
      </Wrapper>
    </>
  );
};

export default VideoGrid;
