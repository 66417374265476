
import React from 'react';

const Spinner = () => (
  <div id="preloader">
    <div id="loader"></div>
  </div>
);

export default Spinner;
