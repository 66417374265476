import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

const Hero = styled.div`
  .bottom-container {
    height: 100%;
    overflow: hidden;
  }

  .bottom-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    place-items: center;
    
    margin-top: 10rem;
    margin-bottom: 6rem;

  .bottom-heading {
    font-size: 4rem;
    color: #fff;
    margin: 0 2rem;
  }

  .CTA-button-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .CTA-button {
    margin: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .CTA-button:hover {
    background-color: #000;
    color: #fff;
  }

  .button-spacer {
    visibility: hidden;
  }
}
`;

const BottomSection = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/contact');
    history.go('/contact');
  };

  return (
    <Hero>
      <div className="bottom-container">
        <div className="bottom-content">
          <h3 className="bottom-heading">
            Stay Connected with Rock Solid Skulls
          </h3>
          <div className="CTA-button">
            <style type="text/css">
              {`
                  .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                  }

                  .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                  }
                  .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                              }

              `}
            </style>
          </div>
          <div className="CTA-button-container">
            {/* <Button variant="flat" size="xxl" onClick={handleButtonClick}>
              Newsletter
            </Button>
            <div className="button-spacer">
              <span>ssd</span>
            </div> */}
            <Button variant="flat" size="xxl" onClick={handleButtonClick}>
              Contact
            </Button>
          </div>
        </div>
      </div>
    </Hero>
  );
};

export default BottomSection;
