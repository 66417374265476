import styled from 'styled-components';

// const CardFront = styled.div`
//   background-color: #fff;
//   border: 3px solid var(--purple);
//   border-radius: 10px;
//   filter: drop-shadow(0px 4px 18px rgba(255, 255, 255, 0.3));
//   display: flex;
//   flex-direction: column;
//   margin-bottom: 1rem;
//   padding: 1rem;
//   overflow: hidden;
//   width: 100%;
//   height: 100%;
//   transition: transform 0.6s ease;

//   &:hover {
//     filter: drop-shadow(0px 4px 18px rgba(255, 255, 255, 0.6));
//   }

//   img {
//     height: auto;
//     max-width: 100%;
//   }

//   p {
//     font-weight: 400;
//     line-height: 1.5;
//     margin-bottom: 1rem;
//   }

//   .card-footer {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     background-color: var(--grey);
//     padding: 0.5rem 1rem;

//     span {
//       font-size: 1rem;
//       font-weight: 400;
//       color: var(--purple);
//     }

//     button {
//       background-color: var(--purple);
//       border: none;
//       border-radius: 4px;
//       color: #fff;
//       cursor: pointer;
//       font-size: 1rem;
//       font-weight: 600;
//       padding: 0.5rem 1rem;

//       &:hover {
//         background-color: var(--green);
//       }
//     }
//   }
// `;

const CardFront = styled.div`
  &.hidden {
    transform: rotateY(180deg);
    opacity: 0;
    pointer-events: none;
  }

  &.show {
    transform: rotateY(0deg);
    opacity: 1;
    pointer-events: all;
  }
`;

export default CardFront;
