import Layout from '../components/Layout';
import VideoGrid from '../components/videos/VideoGrid';

export default function Videos() {
  return (
    <>
      <Layout>
        <>
          <VideoGrid></VideoGrid>
        </>
      </Layout>
    </>
  );
}
