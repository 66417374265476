import React, { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';

const BlogPostWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
`;

const BlogImage = styled.img`
  width: 100%;
  height: auto;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 2rem;

  @media only screen and (max-width: 425px) {
    max-height: 180px;
    object-fit: contain;
  }
`;

const BlogTitle = styled.h1`
  font-size: 2.5rem;
  margin-bottom: 1rem;
  text-align: center;
`;

const BlogDescription = styled.p`
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
  text-align: center;
  color: #fff;
`;

const BlogContent = styled.div`
  font-size: 1.4rem;
  line-height: 1.6;
  text-align: left;
  color: #fff;
  @media only screen and (max-width: 425px) {
    text-align: center;
  }
`;

const BlogNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  border: none;
  background-color: var(--green);
  box-shadow: none;
  border-radius: 5px;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: var(--red);
  }
`;

const SingleBlogPost = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [posts, setPosts] = useState([]);

  // get all posts from db
  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await fetch(`${BASE_URL}/blog`);
        const data = await response.json();
        // console.log('blog db return', data);
        setPosts(data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    }

    fetchPosts();
  }, []);

  console.log(posts);

  const { id } = useParams();
  const history = useHistory();

  const currentIndex = posts.findIndex((post) => post.id === Number(id));

  if (currentIndex === -1) {
    return <div></div>;
  }

  const currentPost = posts[currentIndex];
  const { image, title, description, content } = currentPost;

  const navigateToPrevious = () => {
    const previousIndex = currentIndex - 1;
    if (previousIndex >= 0) {
      const previousPost = posts[previousIndex];
      history.push(`/blog/${previousPost.id}`);
    }
  };

  const navigateToNext = () => {
    const nextIndex = currentIndex + 1;
    if (nextIndex < posts.length) {
      const nextPost = posts[nextIndex];
      history.push(`/blog/${nextPost.id}`);
    }
  };

  const navigateBack = () => {
    history.push('/blog');
  };

  const isPreviousDisabled = currentIndex === 0;
  const isNextDisabled = currentIndex === posts.length - 1;

  return (
    <BlogPostWrapper>
      <BlogImage src={image} alt={title} />
      <BlogTitle>{title}</BlogTitle>
      <BlogNavigation>
        <Button onClick={navigateToPrevious} disabled={isPreviousDisabled}>
          ◀︎
        </Button>
        <Button onClick={navigateBack}>⏎</Button>
        <Button onClick={navigateToNext} disabled={isNextDisabled}>
          ►
        </Button>
      </BlogNavigation>
      {/* <BlogDescription>{description}</BlogDescription> */}
      <BlogContent>{content}</BlogContent>
      <BlogNavigation>
        <Button onClick={navigateToPrevious} disabled={isPreviousDisabled}>
          ◀︎
        </Button>
        <Button onClick={navigateBack}>⏎</Button>
        <Button onClick={navigateToNext} disabled={isNextDisabled}>
          ►
        </Button>
      </BlogNavigation>
    </BlogPostWrapper>
  );
};

export default SingleBlogPost;
