import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 4rem;
  position: relative;
  height: 100%;
  overflow: hidden;

  @media screen and (max-width: 850px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* padding: 0 2rem; */
  height: 100%;

  @media screen and (max-width: 850px) {
    flex-basis: 100%;
    margin-bottom: 4rem;
  }
`;

const Title = styled.h1`
  font-size: 5rem;
  margin-top: 10rem;
  padding: 4rem;
  text-align: center;
`;

const Heading = styled.h2`
  font-size: 4rem;
  margin-top: 4rem;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  text-align: left;
  color: var(--white);
  @media screen and (max-width: 850px) {
    flex-basis: 100%;
    /* padding: 2rem; */
  }
 
`;

const ImageContainer = styled.div`
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  position: relative;
  
`;

const Image = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
  @media screen and (max-width: 850px) and (min-width: 466px){
    
    padding: 20%;
  }
`;

const BtnContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const ThreeColumnSection = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/learn');
  };

  return (
    <>
      <style type="text/css">
        {`
                  .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                  }

                  .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                  }
                  .btn-xxl{
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    box-shadow: none;
                              }
                    .btn-xl {
                      
                      box-shadow: none;
                                }         

              `}
      </style>
      <Title>Our Trusted Experts</Title>

      <Wrapper>
        <Column>
          <ImageContainer>
            <Image src="https://jawaragordon.com/img/rss/Jeff-600x400.jpeg" />
          </ImageContainer>
          <Heading>Jeff</Heading>
          <Paragraph>
            Keeper of the Legendary Ancient Crystal Skulls. A lifelong
            intuitive, Jeff has the ability to connect with and access
            information from the Divine Source Energy.
          </Paragraph>
          <BtnContainer>
            <Button variant="flat" size="xl" onClick={handleButtonClick}>
              Learn More
            </Button>
          </BtnContainer>
        </Column>
        <Column>
          <ImageContainer>
            <Image src="https://jawaragordon.com/img/rss/Tynan.jpeg" />
          </ImageContainer>
          <Heading>Tynan</Heading>
          <Paragraph>
            The owner of Hidden Gem SRQ who brings his love of Crystals & Art to
            the world. Gemstones, Handmade Jewelry and Laser Engraved Art are
            his specialty!
          </Paragraph>
          <BtnContainer>
            <Button variant="flat" size="xl" onClick={handleButtonClick}>
              Learn More
            </Button>
          </BtnContainer>
        </Column>
        <Column>
          <ImageContainer>
            <Image src="https://jawaragordon.com/img/rss/crystal%20cave.webp" />
          </ImageContainer>
          <Heading>Susan</Heading>
          <Paragraph>
            Head honcho of The Crystal Cave with one of the largest collections
            of crystals and stones in South Florida. She prides herself on
            carrying all natural specimens!
          </Paragraph>
          <BtnContainer>
            <Button variant="flat" size="xl" onClick={handleButtonClick}>
              Learn More
            </Button>
          </BtnContainer>
        </Column>
      </Wrapper>
    </>
  );
};

export default ThreeColumnSection;
