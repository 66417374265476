import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Card from '../../components/Card';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  /* justify-content: space-between; */
  margin: auto;
  position: relative;
  overflow: hidden;
  max-width: 696px;

  @media (max-width: 500px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 2rem;
  }
`;

const Column = styled.div`
  width: 50%;
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 100%;
    margin: auto;
    margin-bottom: 2rem;
    padding: 2rem;
  }
`;

const Heading = styled.h1`
  font-size: 3rem;
  margin-bottom: 2rem;

  @media (max-width: 500px) {
    font-size: 2rem;
  }
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  color: var(--black);
`;

const ImageCarousel = styled.div`
  display: flex;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  margin-right: 1rem;
  padding: 1rem;
  object-fit: cover;
`;
const BookShelfImageContainer = styled.div`
  /* position: relative;
  display: inline-block; */
  cursor: pointer;
`;

const BookShelfImage = styled.img`
  display: flex;
  max-width: 600px;
  height: 100%;
  margin: auto;
  padding: 1rem;
  object-fit: cover;

  @media (max-width: 500px) {
    width: 75%;
  }

  &:hover {
    animation: shake 0.3s linear 1;
  }

  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-2px);
    }
    50% {
      transform: translateX(2px);
    }
    75% {
      transform: translateX(-2px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;

const TwoColumnCards = () => {
  const history = useHistory();

  const handleButtonClick = (value) => {
    if (value === 'Library') {
      history.push('/learn/library');
    }
    if (value === 'FAQ') {
      history.push('/learn/faq');
    }
    if (value === 'videos') {
      history.push('/learn/videos');
    }
  };

  const handleTextLinkClick = () => {
    history.push('/learn/library');
  };

  return (
    <>
      <BookShelfImageContainer>
        <a onClick={handleTextLinkClick}>
          <BookShelfImage src="https://rocksolidskulls.com/img/encyclopedia.png" />
        </a>
      </BookShelfImageContainer>
      <Wrapper>
        <Column>
          <div className="shop-button">
            <style type="text/css">
              {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                    box-shadow: none;
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    }

                    `}
            </style>
          </div>
          <Button
            variant="flat"
            size="xxl"
            value="Library"
            onClick={() => handleButtonClick('Library')}
          >
            Library
          </Button>
        </Column>
        <Column>
          <div className="shop-button">
            <style type="text/css">
              {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                    box-shadow: none;
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    }

                    `}
            </style>
          </div>
          <Button
            variant="flat"
            size="xxl"
            value="FAQ"
            onClick={() => handleButtonClick('FAQ')}
          >
            FAQ
          </Button>
        </Column>
        <Column>
          <div className="shop-button">
            <style type="text/css">
              {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    }

                    `}
            </style>
          </div>
          <Button
            variant="flat"
            size="xxl"
            value="videos"
            onClick={() => handleButtonClick('videos')}
          >
            Videos
          </Button>
          {/* </Card> */}
        </Column>
      </Wrapper>
    </>
  );
};

export default TwoColumnCards;
