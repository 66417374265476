import Layout from '../components/Layout';
import styled from 'styled-components';
import TwoColumnImageRight from '../components/about/TwoColumnImageRight';
import HeroSection from '../components/about/HeroSection';
import TwoColumnImageRightSecond from '../components/about/TwoColumnImageRightSecond';
import ThreeColumnSection from '../components/about/ThreeColumnSection';

const AboutStyles = styled.nav`
  background-color: var(--black);

  .logo-main {
    width: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo-main img {
    max-width: 50%;
    margin: auto;
    padding-top: 1rem;
  }
`;

export default function About() {
  return (
    <>
      <Layout>
        <>
          <AboutStyles>
            <TwoColumnImageRight></TwoColumnImageRight>
            <HeroSection></HeroSection>
            <TwoColumnImageRightSecond></TwoColumnImageRightSecond>
            <ThreeColumnSection></ThreeColumnSection>
          </AboutStyles>
        </>
      </Layout>
    </>
  );
}
