import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import debounce from 'lodash.debounce';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const CartContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 50vw;
  height: 100%;
  min-height: 50vh;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;

  @media screen and (max-width: 600px) {
    max-width: 100vw;
    margin: 20px;
  }
`;

const CartHeader = styled.h2`
  margin: 0;
  font-size: clamp(2rem, 1.5vw, 1.5rem);
`;

const CartList = styled.ul`
  list-style: none;
  padding: 0;
  width: 100%;
`;

const CartZipcode = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 33%;
  margin-left: 70%;
  margin-right: 1rem;
  margin-bottom: 0rem;
  /* padding: 10px; */
  /* background-color: #f5f5f5; */
  border-radius: 5px;
`;
const CartItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 5px;
`;

const CartItemName = styled.span`
  font-weight: bold;
  margin-right: 10px;
  font-size: clamp(1rem, 1vw, 1rem);
`;

const CartItemPrice = styled.span`
  margin-right: 10px;
  font-size: clamp(1rem, 1vw, 1rem);
`;

const CartItemQuantity = styled.span`
  margin-right: 10px;
  font-size: clamp(1rem, 1vw, 1rem);
`;

const CartItemTotal = styled.span`
  font-weight: bold;
  font-size: clamp(1rem, 1vw, 1rem);
`;

const CartTotal = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
`;

const CartZipcodeLabel = styled.span`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 50%;
  margin-left: 50%;
  /* width: 10rem; */
  font-weight: bold;
  margin-bottom: -1.5rem;
  margin-top: 1rem;
  font-size: clamp(0.8rem, 1vw, 1rem);
  color: var(--white);
`;
const CartShippingLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
  font-size: clamp(1rem, 1vw, 1rem);
  color: var(--white);
`;
const CartTotalLabel = styled.span`
  font-weight: bold;
  margin-right: 10px;
  font-size: clamp(1rem, 1vw, 1rem);
  color: var(--white);
`;

const CartTotalAmount = styled.span`
  font-weight: bold;
  font-size: clamp(1rem, 1.5vw, 1.5rem);
  color: var(--white);
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 600px;
  margin: 12rem auto 0;
  padding: 2rem;
  /* border: 1px solid #dcdcdc; */
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: transparent;

  @media only screen and (max-width: 325px) {
    flex-direction: column;
    justify-content: space-between;
  }

  .btn-flat {
    margin: 0.5rem;
  }
`;

const ShoppingCart = ({ products, buyerToken }) => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [showOrderCompleteModal, setShowOrderCompleteModal] = useState(false);
  const [orderConfirm, setOrderConfirm] = useState('');
  const [zipcode, setZipcode] = useState('');
  const [shippingCost, setShippingCost] = useState('');
  const [cartQuantity, setCartQuantity] = useState(0);
  const [cartProducts, setCartProducts] = useState([]);

 
  useEffect(() => {
    setCartProducts(
      products.map((product, index) => ({
        ...product,
        quantity: 0,
        key: index // Add a unique key prop based on the index
      }))
    );
  }, [products]);

  const [formBillingData, setFormBillingData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    address: '',
    city: '',
    state: '',
    zipcode: ''
  });
  const [formShippingData, setFormShippingData] = useState({
    shippingAddress: '',
    shippingCity: '',
    shippingState: '',
    shippingZipcode: ''
  });
  const [formErrors, setFormErrors] = useState({});
  const [sameAddress, setSameAddress] = useState(true);
  const [isZipcodeEntered, setIsZipcodeEntered] = useState(false);

  const totalPrice = products.reduce((prev, product) => {
    return prev + product.product.price * 1;
  }, 0);
  const subTotal = totalPrice;

  const totalPriceWithShipping = totalPrice + shippingCost;

  const handleDelete = async (id) => {
    
    const token = localStorage.getItem('token');
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ id })
    };
    try {
      const response = await fetch(`${BASE_URL}/cart/delete/`, requestOptions);
      if (!token) {
        return;
      }

      if (response.ok) {
      } else {
        console.error('Failed to delete item from cart');
      }
    } catch (error) {
      console.error(error);
    }
  };

  // validate form inputs
  const validateForm = () => {
    const errors = {
      email: false,
      firstName: false,
      lastName: false,
      address: false,
      city: false,
      state: false,
      zipcode: false
    };

    if (!formBillingData.email) {
      errors.email = true;
    }

    if (!formBillingData.firstName) {
      errors.firstName = true;
    }

    if (!formBillingData.lastName) {
      errors.lastName = true;
    }

    if (!formBillingData.address) {
      errors.address = true;
    }

    if (!formBillingData.city) {
      errors.city = true;
    }

    if (!formBillingData.state) {
      errors.state = true;
    }

    if (!formBillingData.zipcode) {
      errors.zipcode = true;
    }

    setFormErrors(errors);

    return Object.values(errors).every((error) => !error);
  };

  // automatically fetch shipping cost after zip is entered
  useEffect(() => {
    if (zipcode.length >= 5) {
      handleAutoZipSubmit();
    }
  }, [zipcode]);

  const handleAutoZipSubmit = async (e) => {
    const fetchData = async () => {
   
      try {
        const response = await fetch(`${BASE_URL}/submit/shipping`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ zipcode: zipcode })
        });

        if (response.ok) {
          const data = await response.json();
          setShippingCost(Math.ceil(data));

        } else {
          throw new Error('Server error ' + response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

   

    if (zipcode.length >= 5) {
      fetchData();
    }

   
  };

  const handleZipSubmit = async (e) => {
    e.preventDefault();

    
    setIsZipcodeEntered(true);

    const fetchData = async () => {
      // console.log('inside fetchData', { zipcode: zipcode });
      try {
        const response = await fetch(`${BASE_URL}/submit/shipping`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ zipcode: zipcode })
        });

        if (response.ok) {
          const data = await response.json();
          setShippingCost(Math.ceil(data));
          // console.log('res data', Math.ceil(data));
        } else {
          throw new Error('Server error ' + response.status);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    // console.log('zipcode.length', zipcode);

    // const debouncedFetchData = debounce(fetchData, 50);

    if (zipcode.length >= 5) {
      fetchData();
    }

    // return () => {
    //   debouncedFetchData.cancel();
    // };
  };

  // const handleZipInputChange = (e) => {
  //   const { value } = e.target;
  //   setZipcode(value);
  // };

  const handleZipInputChange = (event) => {
    const enteredValue = event.target.value;
    const numbersOnly = /^[0-9]*$/;

    if (enteredValue.length < 5) {
      setIsZipcodeEntered(false);
    }

    if (enteredValue.length === 5) {
      setIsZipcodeEntered(true);
    }

    if (numbersOnly.test(enteredValue) || enteredValue === '') {
      // Update the state or perform any other desired action
      setZipcode(enteredValue);
    }

    
    // change button state for zipcode
    if (formBillingData.zipcode < 5) {
      setIsZipcodeEntered(false);
    }

  };

  const handleOrderSubmit = async () => {
    const isFormValid = validateForm();

    if (!isFormValid || !isZipcodeEntered) {
      return;
    }

    let buyerId;

    const token = localStorage.getItem('token');
    if (token) {
      // Split the token into its three parts: header, payload, and signature
      const [header, payload] = token.split('.');

      // Decode the payload from base64
      const decodedPayload = atob(payload);

      // Parse the decoded payload as JSON
      const { id } = JSON.parse(decodedPayload);

      // Store the buyer's ID in a locally scoped variable
      buyerId = id;

      // Use the buyerId variable as needed
      // console.log('Buyer ID:', buyerId);
    } else {
      // console.log('No buyer found');
    }

    const orderData = products.map((product) => ({
      name: product.product.name,
      description: product.product.description,
      price: product.product.price
    }));

    const billingInfo = ` \n Customer Name: ${formBillingData.firstName} ${formBillingData.lastName} \n Customer Email: \n ${formBillingData.email}\n Customer Address: \n ${formBillingData.address}\n ${formBillingData.city}\n ${formBillingData.state}\n ${formBillingData.zipcode}`;

    let shippingInfo;

    if (Object.keys(formShippingData).length === undefined) {
      shippingInfo = 'Billing and shipping address are the same.';
    }

    // console.log('formShippingData', formShippingData.length);

    if (Object.keys(formShippingData).length !== 0) {
      shippingInfo = `\n Customer Name: ${formBillingData.firstName} ${formBillingData.lastName} \n Customer Address: \n ${formShippingData.shippingAddress}\n ${formShippingData.shippingCity}\n ${formShippingData.shippingState}\n ${formBillingData.shippingZipcode}`;
    }

    const emailContent = `Customer ID: ${buyerId}\n Customer Billing Info: ${billingInfo}\n Customer Shipping Info: ${shippingInfo}\n Product List:\n ${orderData
      .map(
        (product) =>
          `• ${product.name}\nDescription: ${product.description}\nPrice: $${product.price}\n\n`
      )
      .join('')}\nTotal Price: $${totalPrice}`;

    const res = await fetch(`${BASE_URL}/submit/order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({ emailContent })
    });

    if (!res.ok) {
      alert('Order failed');
    }

    if (res.ok) {
      setOrderConfirm(
        `Order complete, a confirmation was sent to this account's email address.`
      );
      setTimeout(() => {
        setShowOrderCompleteModal(true);
      }, 1000);
    }

    if (res.status === 403) {
      setOrderConfirm('You must sign in to complete an order');
    }
    if (res.status === 500) {
      setOrderConfirm('Server error ');
    }

    setFormBillingData({
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipcode: ''
    });

    setShowModal(false);

    cartProducts.forEach((product) => handleDelete(product.product.id));

    setIsZipcodeEntered(false);

    setCartProducts([]);
  };

  const handleModalToggle = () => {
    if (!zipcode) {
      setIsZipcodeEntered(false);
    }

    setFormBillingData({
      email: '',
      firstName: '',
      lastName: '',
      address: '',
      city: '',
      state: '',
      zipcode: zipcode
    });

    setShowModal(!showModal);
  };

  const handleOrderCompleteModal = () => {
    setShowOrderCompleteModal(false);
  };

  const handleInputChange = (e) => {
    

    const { name, value } = e.target;
    setFormBillingData({
      ...formBillingData,
      [name]: value
    });

    setFormShippingData({
      ...formShippingData,
      [name]: value
    });
  };

  // same shipping address toggle
  const handleCheckboxChange = (e) => {
    setSameAddress(e.target.checked);
  };
  const [quantity, setQuantity] = useState(0);

  let globalQuantity = quantity;

  // console.log('globalQuantity', globalQuantity);

  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value);
    setQuantity(newQuantity);
    setCartQuantity(newQuantity);
    // Update the global variable with the new quantity value
    globalQuantity = newQuantity;
  };

  const multiplier = (productTotal, quantity) => {
    return productTotal * quantity;
  };

  const handleButtonClick = (value) => {
    if (value === 'signin') {
      history.push('/signin');
    }
    if (value === 'signup') {
      history.push('/signup');
    }
  };

  // console.log('isZipcodeEntered', isZipcodeEntered);
  return (
    <>
      <CartContainer>
        <CartHeader>Shopping Cart</CartHeader>
        <CartList>
          {products.map((product) => (
            <CartItem key={product._id}>
              <style type="text/css">
                {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      font-family: 'Montserrat';
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: .5rem 1rem
      font-size: 1.5rem;
      box-shadow: none;
    }

    `}
              </style>
              <Button
                variant="outline"
                size="sm"
                onClick={() => handleDelete(product.product._id)}
              >
                ❌
              </Button>

              <CartItemName>{product.product.name}</CartItemName>

              <CartItemPrice>{product.product.quantity}</CartItemPrice>
              <CartItemPrice>
                {product.product.quantity}

                {/* <input
                  type="number"
                  id="quantity"
                  name="quantity"
                  min="1"
                  max="5"
                  onChange={handleQuantityChange}
                ></input> */}

                {/* <NumberButton onClick={handleIncrement}>+</NumberButton> */}
                {/* </NumberSelector> */}
                {/* </Form.Group> */}
                <CartItemPrice>{}</CartItemPrice>
              </CartItemPrice>
              <CartItemPrice>${product.product.price}</CartItemPrice>
            </CartItem>
          ))}
        </CartList>

        <style type="text/css">
          {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      font-family: 'Montserrat';
      box-shadow:none;
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem;
      font-size: 1.5rem;
      box-shadow: none;
    }

    `}
        </style>

        {!localStorage.token && (
          <div className="btn-enter">
            <style type="text/css">
              {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      box-shadow:none;
      
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem 2.5rem;
      font-size: 1.5rem;
      
    }

    `}
            </style>
            <ButtonContainer>
              <Button
                variant="flat"
                size="sm"
                value="signin"
                onClick={() => handleButtonClick('signin')}
              >
                Sign in
              </Button>{' '}
              <Button
                variant="flat"
                size="sm"
                value="enter"
                onClick={() => handleButtonClick('signup')}
              >
                Sign up
              </Button>
            </ButtonContainer>
          </div>
        )}

        {localStorage.token && (
          <>
            <CartTotal>
              <CartShippingLabel>Subtotal:</CartShippingLabel>

              <CartTotalAmount>${subTotal}</CartTotalAmount>
            </CartTotal>
            <CartZipcodeLabel></CartZipcodeLabel>
            <CartZipcode>
              <Form onSubmit={handleZipSubmit}>
                <Form.Group controlId="formZipcode">
                  <Form.Label></Form.Label>
                  <Form.Control
                    type="zipcode"
                    name="zipcode"
                    placeholder="Enter ZIP"
                    value={zipcode}
                    onChange={handleZipInputChange}
                    isInvalid={formErrors.zipcode}
                    maxLength={5}
                    required
                  />
                </Form.Group>
              </Form>
            </CartZipcode>

            <CartTotal>
              <CartShippingLabel>Shipping:</CartShippingLabel>

              <CartTotalAmount>${shippingCost}</CartTotalAmount>
            </CartTotal>
            <CartTotal>
              <CartTotalLabel>Total:</CartTotalLabel>

              <CartTotalAmount>${totalPriceWithShipping}</CartTotalAmount>
            </CartTotal>

            {isZipcodeEntered ? (
              <Button variant="flat" size="sm" onClick={handleModalToggle}>
                Complete Order
              </Button>
            ) : (
              <Button variant="flat" size="sm">
                Enter ZIP ↑
              </Button>
            )}
          </>
        )}
      </CartContainer>

      <Modal show={showModal} onHide={handleModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title>Complete Order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleOrderSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label></Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="email"
                value={formBillingData.email}
                onChange={handleInputChange}
                isInvalid={formErrors.email}
                required
              />
              <Form.Control.Feedback type="invalid">
                Email is required.
              </Form.Control.Feedback>
              <Form.Label></Form.Label>
              <Form.Control
                type="name"
                name="firstName"
                placeholder="first name"
                value={formBillingData.firstName}
                onChange={handleInputChange}
                isInvalid={formErrors.firstName}
                required
              />
              <Form.Control.Feedback type="invalid">
                First Name is required.
              </Form.Control.Feedback>
              <Form.Label></Form.Label>
              <Form.Control
                type="name"
                name="lastName"
                placeholder="last name"
                value={formBillingData.lastName}
                onChange={handleInputChange}
                isInvalid={formErrors.lastName}
                required
              />
              <Form.Control.Feedback type="invalid">
                Last Name is required.
              </Form.Control.Feedback>
              <Form.Label></Form.Label>
              <Form.Control
                type="address"
                name="address"
                placeholder="billing address"
                value={formBillingData.address}
                onChange={handleInputChange}
                isInvalid={formErrors.address}
                required
              />
              <Form.Control.Feedback type="invalid">
                Billing Address is required.
              </Form.Control.Feedback>
              <Form.Label></Form.Label>
              <Form.Control
                type="city"
                name="city"
                placeholder="city"
                value={formBillingData.city}
                onChange={handleInputChange}
                isInvalid={formErrors.city}
                required
              />
              <Form.Control.Feedback type="invalid">
                City is required.
              </Form.Control.Feedback>
              <Form.Label></Form.Label>
              <Form.Control
                type="state"
                name="state"
                placeholder="state"
                value={formBillingData.state}
                onChange={handleInputChange}
                isInvalid={formErrors.state}
                required
              />
              <Form.Control.Feedback type="invalid">
                State is required.
              </Form.Control.Feedback>
              <Form.Label></Form.Label>
              <Form.Control
                type="zipcode"
                name="zipcode"
                maxLength={5}
                placeholder="zip code"
                value={zipcode}
                onChange={handleInputChange}
                isInvalid={formErrors.zipcode}
              />
              {/* <Form.Control.Feedback type="invalid">
                Zipcode is required.
              </Form.Control.Feedback> */}
              <Form.Group>
                <Form.Check
                  type="checkbox"
                  label="Is your billing address the same as your shipping address?"
                  checked={sameAddress}
                  onChange={handleCheckboxChange}
                />
              </Form.Group>

              {!sameAddress && (
                <>
                  <Form.Group>
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="address"
                      name="shippingAddress"
                      placeholder="shipping address"
                      value={formShippingData.shippingAddress}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Shipping Address is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="city"
                      name="shippingCity"
                      placeholder="shipping city"
                      value={formShippingData.shippingCity}
                      onChange={handleInputChange}
                      required
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="state"
                      name="shippingState"
                      placeholder="shipping state"
                      value={formShippingData.shippingState}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Shipping State is required.
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label></Form.Label>
                    <Form.Control
                      type="zipcode"
                      name="shippingZipcode"
                      placeholder="shipping zipcode"
                      value={formShippingData.shippingZipcode}
                      onChange={handleInputChange}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      Shipping Zipcode is required.
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </Form.Group>
            <style type="text/css">
              {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      box-shadow: none;
      margin-top:1rem;
      font-family: 'Montserrat';
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem 2.5rem;
      font-size: 1.5rem;
    }

    `}
            </style>
            <Button variant="flat" size="xxl" onClick={handleOrderSubmit}>
              Submit Order
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      <Modal show={showOrderCompleteModal} onHide={handleOrderCompleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Order Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>{orderConfirm}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleOrderCompleteModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ShoppingCart;
