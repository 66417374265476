import React, { useState } from 'react';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useHistory } from 'react-router-dom';

const ContactFormWrapper = styled.div`
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;

  @media only screen and (max-width: 600px) {
    margin: 0 20px;
  }

  h2 {
    margin-top: 0;
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  p {
    font-size: 18px;
    color: #555;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 0 2rem 0;
  text-align: center;
`;

const ContactForm = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    honeypot: ''
  });
  const [formErrors, setFormErrors] = useState({
    name: false,
    email: false,
    message: false
  });
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [submitConfirm, setSubmitConfirm] = useState('');
  const history = useHistory('');

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { name, email, message, honeypot } = formData;

    // Check for honeypot
    if (honeypot) {
      return;
    }

    // validate inputs
    const errors = {};
    if (!name) {
      errors.name = true;
    }
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errors.email = true;
    }
    if (!message) {
      errors.message = true;
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    const res = await fetch(`${BASE_URL}/submit/contact`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ formData })
    });

    if (!res.ok) {
    }

    if (res.ok) {
      setSubmitConfirm('Someone will be in touch with you soon');
      setTimeout(() => {
        history.push('/home');
      }, 2000);
    }

    if (res.status === 400) {
      setSubmitConfirm('Contact failed please try again');
    }
    if (res.status === 500) {
      setSubmitConfirm('Server error ');
    }

    setFormSubmitted(true);
  };

  return (
    <>
      <style type="text/css">
        {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    display: flex;
                    margin: auto;
                    padding: 1rem 2.5rem;
                    margin-top: 1rem;
      
                    font-size: 1.5rem;
                    place-items: center;
                    }

                    `}
      </style>
      <Title>Contact</Title>
      <ContactFormWrapper>
        {formSubmitted ? (
          <div>
            <h2>Thank you for your message!</h2>
            <p>We will be in touch with you soon.</p>
          </div>
        ) : (
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label></Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="user name"
                value={formData.name}
                onChange={handleInputChange}
                isInvalid={formErrors.name}
                required
              />
              {formErrors.name && (
                <Form.Control.Feedback type="invalid">
                  Please enter your name.
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="formEmail">
              <Form.Label></Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="email"
                value={formData.email}
                onChange={handleInputChange}
                isInvalid={formErrors.email}
                required
              />
              {formErrors.email && (
                <Form.Control.Feedback type="invalid">
                  Please enter a valid email address.
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="formMessage">
              <Form.Label></Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                name="message"
                placeholder="message"
                value={formData.message}
                onChange={handleInputChange}
                isInvalid={formErrors.message}
                required
              />
              {formErrors.message && (
                <Form.Control.Feedback type="invalid">
                  Please enter a message.
                </Form.Control.Feedback>
              )}
            </Form.Group>
            <Form.Group controlId="formHoneypot" style={{ display: 'none' }}>
              <Form.Control
                type="text"
                name="honeypot"
                value={formData.honeypot}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Button variant="flat" size="xxl" type="submit">
              Send Message
            </Button>
          </Form>
        )}
      </ContactFormWrapper>
    </>
  );
};

export default ContactForm;
