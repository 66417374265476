import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const BlogWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2px;
  max-width: 1200px;
  margin: 0 auto;
`;

const BlogPost = styled.div`
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  overflow: hidden;
  margin: 2rem 2rem;
`;

const BlogImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;
`;

const BlogTitle = styled.h2`
  color: var(--black);
  font-size: 2rem;
  margin: 20px;
`;

const BlogDescription = styled.p`
  line-height: 1.5;
  font-size: 1rem;
  margin: 0 20px 20px;
`;

const ReadMoreLink = styled(Link)`
  display: flex;
  justify-items: flex-start;
  align-items: end;
  bottom: 0;
  right: 0;
  margin: 0 20px 20px;
  text-align: right;
  font-weight: 800;
  color: var(--purple);
`;

const BlogTemplate = ({ posts }) => {
  // console.log('BlogTemplate', posts);
  return (
    <BlogWrapper>
      {posts.map((post) => (
        <BlogPost key={post.id}>
          <BlogImage src={post.image} alt={post.title} />
          <BlogTitle>{post.title}</BlogTitle>
          <BlogDescription>{post.description}</BlogDescription>
          <ReadMoreLink to={`/blog/${post.id}`}>Read more</ReadMoreLink>
        </BlogPost>
      ))}
    </BlogWrapper>
  );
};

export default BlogTemplate;
