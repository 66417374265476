import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Card from '../components/Card';
import CardFront from '../components/CardFront';
import CardBack from '../components/CardBack';
import Modal from '../components/Modal';
import heartEmpty from '../img/heart-empty.svg';
import heartFilled from '../img/heart-filled.svg';


const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;

  max-width: 900px;
  margin: 4rem auto;

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

const Container = styled.div`
  margin: auto 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const HeartIcon = styled.img`
  display: flex;
  align-self: center;
  height: 25px;
  width: 25px;
  margin: auto;
  padding-top: 1rem;
  cursor: pointer;
`;

const Dropdown = styled.select`
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  margin: auto;
  &:hover {
    background-color: #f5f5f5;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
`;

export default function Jewelry() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [jewelry, setJewelry] = useState([]);
  const [showModal, setShowModal] = useState(Array(jewelry.length).fill(false));
  const [itemId, setItemId] = useState('');
  const [favorites, setFavorites] = useState([]);
  const [heartFilledColor, setHeartFilledColor] = useState([]);
  const [selectedTag, setSelectedTag] = useState('');
  const [tagNames, setTagNames] = useState(['All']);
  const [filteredJewelry, setFilteredJewelry] = useState([]);

  useEffect(() => {
    const fetchJewelry = async () => {
      const res = await fetch(`${BASE_URL}/api/jewelry`);
      const data = await res.json();
      setJewelry(data);

      const allTags = data.flatMap((jewelry) => jewelry.tags);
      const uniqueTags = [...new Set(allTags)];
      setTagNames(uniqueTags);
    };

    fetchJewelry();
  }, []);

  

  useEffect(() => {
    const fetchJewelry = async () => {
      const res = await fetch(`${BASE_URL}/api/jewelry`);
      const data = await res.json();
      setJewelry(data);
    };

    fetchJewelry();
  }, []);

  const handleButtonClick = (id) => {
    const cardFront = document.getElementById(`cardFront-${id}`);
    const cardBack = document.getElementById(`cardBack-${id}`);
    cardFront.classList.toggle('hidden');
    cardFront.classList.toggle('show');
    cardBack.classList.toggle('hidden');
    cardBack.classList.toggle('show');
  };

  // Add to Cart flow

  // const [showModal, setShowModal] = useState(false);

  // Modal confirmation messages
  const title = 'Confirm';
  const message = 'Are you sure?';

 
  const addToCart = (id) => {
    const filteredIndex = filteredJewelry.findIndex((jewelry) => jewelry.id === id);
    const index = jewelry.findIndex((jewelry) => jewelry.id === id);
    if (index !== -1 || filteredIndex !== -1) {
      const newShowModal = [...showModal];
      newShowModal[filteredIndex !== -1 ? filteredIndex : index] = true;
      setShowModal(newShowModal);
    }
  };
  
  const handleConfirm = (id) => {
    const filteredIndex = filteredJewelry.findIndex((jewelry) => jewelry.id === id);
    const index = jewelry.findIndex((jewelry) => jewelry.id === id);
    if (index !== -1 || filteredIndex !== -1) {
      const newShowModal = [...showModal];
      newShowModal[filteredIndex !== -1 ? filteredIndex : index] = false;
      setShowModal(newShowModal);
  
      // Trigger the event for adding to cart
      setItemId(id);
    }
  };
  
  const handleCancel = (id) => {
    const filteredIndex = filteredJewelry.findIndex((jewelry) => jewelry.id === id);
    const index = jewelry.findIndex((jewelry) => jewelry.id === id);
    if (index !== -1 || filteredIndex !== -1) {
      const newShowModal = [...showModal];
      newShowModal[filteredIndex !== -1 ? filteredIndex : index] = false;
      setShowModal(newShowModal);
      // Handle cancellation
    }
  };
  

  // Submit item id to POST

  useEffect(() => {
    const addItemToCart = async () => {
      const token = localStorage.getItem('token');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ itemId })
      };

     
      try {
        const res = await fetch(`${BASE_URL}/cart/add`, requestOptions);
        if (res.status === 400) {
          console.log('You already added this to your cart');
        } else {
          
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (itemId) {
      addItemToCart();
    }
  }, [itemId]);

  // updates single card heart state
  useEffect(() => {
    setHeartFilledColor(
      jewelry.map((jewelry) => ({ id: jewelry.id, filled: false }))
    );
  }, [jewelry]);

  const handleHeartClick = (id) => {
    setHeartFilledColor((prevColors) => {
      const updatedColors = [...prevColors];
      const index = updatedColors.findIndex((color) => color.id === id);

      if (index !== -1) {
        updatedColors[index].filled = !updatedColors[index].filled;
        // Call the correct function based on the filled state
        if (updatedColors[index].filled) {
          addJewelryToFavorites(id);
        } else {
          removeJewelryFromFavorites(id);
        }
      }

      return updatedColors;
    });
  };

  const addJewelryToFavorites = async (id) => {
    const token = localStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    try {
      if (!token) {
        return;
      }
      const res = await fetch(`${BASE_URL}/heart/skull/${id}`, requestOptions);

      if (res.status === 200) {
        // Jewelry added to favorites successfully
        const updatedFavorites = [...favorites, id];
        setFavorites(updatedFavorites);
      } else {
        // Handle error case
        console.error('Failed to add skull to favorites');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeJewelryFromFavorites = async (id) => {
    const token = localStorage.getItem('token');
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    try {
      const res = await fetch(
        `${BASE_URL}/heart/delete/skull/${id}`,
        requestOptions
      );

      if (res.status === 200) {
        // Skull removed from favorites successfully
        const updatedFavorites = favorites.filter(
          (jewelryId) => jewelryId !== id
        );
        setFavorites(updatedFavorites);
      } else {
        // Handle error case
        console.error('Failed to remove skull from favorites');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
    // setSelectedTag(tag);
  };

 

  useEffect(() => {
    const filterSkullsByTag = (jewelry, selectedTag) => {
      if (selectedTag === '') {
        return jewelry;
      } else {
        return jewelry.filter((jewelry) => jewelry.tags.includes(selectedTag));
      }
    };

    const filteredJewelry = filterSkullsByTag(jewelry, selectedTag);
    setFilteredJewelry(filteredJewelry);
  }, [jewelry, selectedTag]);

  return (
    <Layout>
      <div>
        <Title>Jewelry Shop</Title>
        <Dropdown value={selectedTag} onChange={handleTagChange}>
          <option value="">All Jewelry</option>
          {tagNames.map((tagName) => (
            <option key={tagName} value={tagName}>
              {tagName}
            </option>
          ))}
        </Dropdown>

        <Wrapper>
          <Container>
            {filteredJewelry.map((jewelry, index) => (
              <React.Fragment key={jewelry.id}>
                <Card>
                  <CardFront className="show" id={`cardFront-${jewelry.id}`}>
                    <img
                      src={`${BASE_URL}/uploads/${jewelry.photo}`}
                      alt={jewelry.name}
                    />

                    <div className="card-body">
                      <h3>{jewelry.name}</h3>
                    </div>

                    <div className="card-footer">
                      <button onClick={() => handleButtonClick(jewelry.id)}>
                        Details
                      </button>
                    </div>
                    <HeartIcon
                      className="heart-icon"
                      src={
                        heartFilledColor.find(
                          (color) => color.id === jewelry.id
                        )?.filled
                          ? heartFilled
                          : heartEmpty
                      }
                      alt="Heart"
                      onClick={() => handleHeartClick(jewelry.id)}
                    />
                  </CardFront>
                  <CardBack className="hidden" id={`cardBack-${jewelry.id}`}>
                    <div className="card-body">
                      {/* <h3>{jewelry.location.address}</h3> */}
                      <p>{jewelry.description}</p>
                    </div>
                    <div className="item-price">{jewelry.tags}</div>
                    <div className="item-price">${jewelry.price}</div>
                    <div className="card-footer">
                      <button onClick={() => addToCart(jewelry.id)}>Add</button>
                      {showModal[index] && (
                        <Modal
                          onConfirm={() => handleConfirm(jewelry.id)}
                          onCancel={() => handleCancel(jewelry.id)}
                          title={title}
                          message={message}
                        >
                          <button onClick={() => handleConfirm(jewelry.id)}>
                            Yes
                          </button>
                          <button onClick={() => handleCancel(jewelry.id)}>
                            No
                          </button>
                        </Modal>
                      )}

                      <div className="btn-spacer"> </div>
                      <button onClick={() => handleButtonClick(jewelry.id)}>
                        Back
                      </button>
                    </div>
                  </CardBack>
                </Card>
              </React.Fragment>
            ))}
          </Container>
        </Wrapper>
      </div>
    </Layout>
  );
}
