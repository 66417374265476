import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

const FormWrapper = styled.div`
  max-width: 400px;
  padding: 20px;
  margin: auto;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;

  h2 {
    margin-top: 0;
    padding-top: 1rem;
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
`;

const LinkText = styled.a`
  font-size: 1rem;
  font-weight: 800;
  cursor: pointer;
  color: #000;

  &:hover {
    color: var(--red);
  }
`;

const SignInForm = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [resetFormData, setResetFormData] = useState({
    email: ''
  });
  const [formErrors, setFormErrors] = useState({
    email: false,
    password: false
  });
  const [serverError, setServerError] = useState('');
  const [passwordResetConfirm, setPasswordResetConfirm] = useState('');
  const [honeypot, setHoneypot] = useState('');
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [validToken, setValidToken] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const history = useHistory();

  const handleSubmit = (e) => {
    e.preventDefault();
    const { email, password } = formData;

    // validate inputs
    const errors = {};
    if (!email || !/\S+@\S+\.\S+/.test(email)) {
      errors.email = true;
    }
    if (!password) {
      errors.password = true;
    }
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
    }

    // check for honeypot
    if (honeypot) {
      return;
    }

    setFormSubmitted(true);
  };

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();

    try {
      const res = await fetch(`${BASE_URL}/api/account/reset`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email: resetFormData.email })
      });

      if (res.status === 500) {
        setPasswordResetConfirm(
          'This email account is invalid or cannot receive messages'
        );
      }

      if (res.ok) {
        setPasswordResetConfirm(
          'Check your email, password request has been sent'
        );
      }

      if (res.status === 400) {
        setPasswordResetConfirm('Please try a different email address');
      }
    } catch (error) {
      console.error('An error occurred', error);
    }

    setShowModal(false);
  };

  useEffect(() => {
    const handleSubmit = async () => {
      const { email, password } = formData;

      // validate inputs
      const errors = {};
      if (!email || !/\S+@\S+\.\S+/.test(email)) {
        errors.email = true;
      }
      if (!password) {
        errors.password = true;
      }
      if (Object.keys(errors).length > 0) {
        setFormErrors(errors);
        return;
      }

      // submit form data
      try {
        const response = await fetch(`${BASE_URL}/api/signin`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ email, password })
        });

        if (response.ok) {
          const data = await response.json();
          // JWT token in the response
          localStorage.setItem('token', data.token);

          // console.log('returned server jwt token', data.token);
          // console.log('localStorage', localStorage);
          // console.log('formSubmitted', formSubmitted);
          setValidToken(true);
          setTimeout(() => {
            handleCorrectLoginRedirect();
          }, 2000);
        } else {
          throw new Error('Sign in failed');
        }
      } catch (error) {
        console.error(error);
      }
      if (!localStorage.token) {
        setFormSubmitted(false);
        setServerError('Invalid email or password');
        setTimeout(() => {
          setServerError('');
        }, 5000);
        throw new Error('Sign-in failed');
      }
    };

    function handleCorrectLoginRedirect() {
      history.push('/home');
      history.go('/home');
    }

    if (formSubmitted) {
      handleSubmit();
    }
  }, [formSubmitted, formData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleResetInputChange = (e) => {
    const { name, value } = e.target;
    setResetFormData({
      ...formData,
      [name]: value
    });
  };

  const handleButtonClick = (value) => {
    if (value === 'enter') {
      history.push('/home');
      history.go('/home');
    }
    if (value === 'signup') {
      history.push('/signup');
      history.go('/signup');
    }
  };

  const handleModalToggle = () => {
    setShowModal(!showModal);
  };

  // console.log('server error', serverError);

  return (
    <>
      <style type="text/css">
        {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      font-family: 'Montserrat';
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem;
      font-size: 1.5rem;
      box-shadow: none;
    }

    `}
      </style>
      <FormWrapper>
        {validToken ? (
          <div>
            <h2>Welcome Back!</h2>
          </div>
        ) : (
          <Form className="signin-form" onSubmit={handleSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label></Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="email"
                autoComplete="new-email"
                value={formData.email}
                onChange={handleInputChange}
                isInvalid={formErrors.email}
                required
              />
            </Form.Group>
            <Form.Group controlId="formPassword">
              <Form.Label></Form.Label>
              <Form.Control
                type="password"
                name="password"
                autoComplete="new-password"
                placeholder="password"
                value={formData.password}
                onChange={handleInputChange}
                isInvalid={formErrors.password}
                required
              />
            </Form.Group>
            <div>
              <Form.Label htmlFor="honeypot" style={{ display: 'none' }}>
                Leave this field empty:
              </Form.Label>
              <Form.Control
                type="text"
                name="honeypot"
                id="honeypot"
                value={honeypot}
                onChange={(e) => setHoneypot(e.target.value)}
                style={{ display: 'none' }}
              />
            </div>

            <LinkText variant="flat" size="md" onClick={handleModalToggle}>
              Forgot password?
            </LinkText>
            {serverError && (
              <div className="error-section">
                <h5>{serverError}</h5>
              </div>
            )}
            {passwordResetConfirm && (
              <div className="error-section">
                <h5>{passwordResetConfirm}</h5>
              </div>
            )}

            <div className="btn-form">
              <Button variant="flat" size="xxl" type="submit">
                Sign in
              </Button>
              <Button
                variant="flat"
                size="xxl"
                value="signup"
                onClick={() => handleButtonClick('signup')}
              >
                Sign up
              </Button>
            </div>
          </Form>
        )}
      </FormWrapper>
      <Modal show={showModal} onHide={handleModalToggle}>
        <Modal.Header closeButton>
          <Modal.Title>Forgot Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleForgotPasswordSubmit}>
            <Form.Group controlId="formEmail">
              <Form.Label></Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="email"
                className="form-email"
                value={resetFormData.email}
                onChange={handleResetInputChange}
                isInvalid={formErrors.email}
                required
              />
            </Form.Group>
            <Button variant="flat" size="sm" type="submit">
              Reset Password
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SignInForm;
