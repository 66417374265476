import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const FormWrapper = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;

  h2 {
    margin-top: 0;
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }

  p {
    font-size: 18px;
    color: #555;
    text-align: center;
  }
`;

const ResetPasswordForm = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordResetConfirm, setPasswordResetConfirm] = useState('');
  const history = useHistory();
  const params = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = params.token;

    // console.log(token);

    const formData = {
      token: token,
      password,
      confirmPassword
    };

    // console.log(formData);

    try {
      const res = await fetch(`${BASE_URL}/api/account/reset/password`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (!res.ok) {
        throw new Error('Failed to update');
      }

      const data = await res.json();
      // JWT token in the res
      localStorage.setItem('token', data.token);
     
      const localSessionId = localStorage.getItem('token');
     
      if (localSessionId) {
        setPasswordResetConfirm('Password reset successfully');
        setTimeout(() => {
          history.push('/home');
          history.go('/home');
        }, 2000);
      }
    } catch (error) {
      setPasswordResetConfirm(
        'This email account is invalid or cannot receive messages'
      );
      console.error(error);
    }
  };

  const handleButtonClick = (value) => {
    if (value === 'enter') {
      history.push('/home');
      history.go('/home');
    }
    if (value === 'back') {
      history.push('/signin');
      history.go('/signin');
    }
  };

  return (
    <>
      <FormWrapper>
        <Form onSubmit={handleSubmit}>
          <div>
            <Form.Label htmlFor="password">New Password:</Form.Label>
            <Form.Control
              type="password"
              name="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <div>
            <Form.Label htmlFor="confirmPassword">
              Confirm New Password:
            </Form.Label>
            <Form.Control
              type="password"
              name="confirmPassword"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          {passwordResetConfirm && (
            <div className="error-section">
              <h5>{passwordResetConfirm}</h5>
            </div>
          )}
          <div className="btn-form">
            <style type="text/css">
              {`
    .btn-flat {
      background-color: var(--green);
      color: white;
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem;
      font-size: 1.5rem;
      box-shadow: none;
    }

    `}
            </style>
            <Button variant="flat" size="xxl" type="submit">
              Reset
            </Button>{' '}
          </div>
        </Form>
      </FormWrapper>
    </>
  );
};

export default ResetPasswordForm;
