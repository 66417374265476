import Layout from '../components/Layout';
import AI_HANDLER from '../components/chat/AI';

export default function Chat() {
  return (
    <>
      <Layout>
        <>
          {/* <section id="setup-container">
            <div class="setup-inner">
              <img src=""></img>
              <div class="speech-bubble-ai" id="speech-bubble-ai">
                <p id="ai-text">
                  lorem ipsum dolor sit amet, consectetur adip.
                </p>
              </div>
            </div>
            <div
              class="setup-inner setup-input-container"
              id="setup-input-container"
            >
              <textarea
                id="setup-textarea"
                placeholder="Ask me a question."
              ></textarea>
              <button class="send-btn" id="send-btn" aria-label="send">
                <img src="" alt="send"></img>
              </button>
            </div>
          </section>

          <section class="output-container" id="output-container">
            <div id="output-img-container" class="output-img-container"></div>
            <h1 id="output-title"></h1>
            <h2 id="output-stars"></h2>
            <p id="output-text"></p>
          </section> */}
          <AI_HANDLER></AI_HANDLER>
        </>
      </Layout>
    </>
  );
}
