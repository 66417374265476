import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Layout from '../components/Layout';
import TwoColumnCards from '../components/favorites/TwoColumnCards';
import Button from 'react-bootstrap/Button';

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 600px;
  margin: 12rem auto 0;
  padding: 2rem;
  /* border: 1px solid #dcdcdc; */
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: transparent;

  @media only screen and (max-width: 425px) {
    flex-direction: column;
    justify-content: space-between;
  }

  .btn-flat {
    margin-bottom: 1rem;
  }
`;

export default function Favorites() {
  const history = useHistory();

  const handleButtonClick = (value) => {
    if (value === 'signin') {
      history.push('/signin');
    }
    if (value === 'signup') {
      history.push('/signup');
    }
  };

  return (
    <>
      <Layout>
        <>
          <Title>Wish List</Title>

          {!localStorage.token && (
            <div className="btn-enter">
              <style type="text/css">
                {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      box-shadow:none;
      
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem 2.5rem;
      font-size: 1.5rem;
      
    }

    `}
              </style>
              <ButtonContainer>
                <Button
                  variant="flat"
                  size="xxl"
                  value="signin"
                  onClick={() => handleButtonClick('signin')}
                >
                  Sign in
                </Button>{' '}
                <Button
                  variant="flat"
                  size="xxl"
                  value="enter"
                  onClick={() => handleButtonClick('signup')}
                >
                  Sign up
                </Button>
              </ButtonContainer>
            </div>
          )}

          <TwoColumnCards></TwoColumnCards>
        </>
      </Layout>
    </>
  );
}
