import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import ShoppingCart from '../components/cart/ShoppingCart';

export default function Cart() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [products, setProducts] = useState([]);
  const [buyerToken, setBuyerToken] = useState('');

  useEffect(() => {
    if (!products) {
      setProducts([]);
    }
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      const token = localStorage.getItem('token');
      const requestOptions = {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        }
      };

      if (!token) {
        return;
      }

      const res = await fetch(`${BASE_URL}/cart/`, requestOptions);
      const data = await res.json();

      setBuyerToken(token);
      setProducts(data);
    };

    fetchProducts();
  }, [products]);

  return (
    <>
      <Layout>
        <ShoppingCart
          products={products}
          buyerToken={buyerToken}
        ></ShoppingCart>
      </Layout>
    </>
  );
}
