import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 2rem 0;
  position: relative;
  height: 100vh;
  overflow: hidden;
  @media (max-width: 768px) {
  }
  @media (max-width: 700px) {
  }
  @media (max-width: 600px) {
    display: flex;
    flex-direction: column;
    padding: 3rem 0 45rem 0;

    /* margin-bottom: 10rem; */
    height: 100%;
    width: 100vw;
  }
`;

const LeftColumn = styled.div`
  width: 50%;
  margin-right: 2rem;
  margin-left: 2rem;
  padding: 0 3rem;
  @media (max-width: 600px) {
    width: 100%;
    padding-top: 28rem;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 2rem;
  margin-top: -18rem;

  @media (max-width: 768px) {
    margin-top: -14rem;
  }
  @media (max-width: 700px) {
    margin-top: -16rem;
  }
  @media (max-width: 600px) {
    margin-top: -65rem;
    width: 75%;
  }
`;

const Heading = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 1rem;
`;

const Paragraph = styled.p`
  font-size: 1.2rem;
  margin: 2rem 0;
  color: var(--white);
`;

const ImageCarousel = styled.div`
  display: flex;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    height: 16px;
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--red);
    border-radius: 1px;
  }

  &:hover {
    cursor: pointer;
  }
`;

const Image = styled.img`
  width: 400px;
  height: 300px;
  margin-right: 1rem;
  object-fit: cover;
`;

const Btn = styled.button`
  .shop-button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }

  .shop-button:hover {
    background-color: #000;
    color: #fff;
  }
`;

const TwoColumnImageLeft = () => {
  const history = useHistory();

  const handleButtonClick = () => {
    history.push('/learn');
    history.go('/learn');
  };

  return (
    <Wrapper>
      <LeftColumn>
        <ImageCarousel>
          <Image src="https://jawaragordon.com/img/rss/rss-product-example-hero.png" />
          <Image src="https://jawaragordon.com/img/rss/12.png" />
          <Image src="https://jawaragordon.com/img/rss/13.png" />
        </ImageCarousel>
        <Paragraph>
          From the history and science of crystal skulls to tips for working
          with them in your meditation practice, our "Learn" section has
          everything you need to take your journey to the next level.
        </Paragraph>

        <div className="shop-button">
          <style type="text/css">
            {`
                    .btn-flat {
                    background-color: var(--green);
                    color: white;
                    font-family: 'Montserrat';
                    }

                    .btn-flat:hover {
                    background-color: #502B3A;
                    color: white;
                    }
                    .btn-xxl {
                    padding: 1rem 2.5rem;
                    font-size: 1.5rem;
                    }

                    `}
          </style>
        </div>
        <Button variant="flat" size="xxl" onClick={handleButtonClick}>
          Learn
        </Button>
      </LeftColumn>
      <RightColumn>
        <Heading>Need Help?</Heading>
        <Paragraph>
          Education is essential for developing a deep understanding of crystal
          skulls. Our FAQ and Blogs answer common questions about crystal
          skulls, while offering in-depth articles on a range of related topics.{' '}
        </Paragraph>
      </RightColumn>
    </Wrapper>
  );
};

export default TwoColumnImageLeft;
