import { useState } from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import heart from '../../img/heart.svg';

const StyledHamburger = styled.button`
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 24px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  box-shadow: none;
  position: absolute;
  top: 40px;
  right: 40px;

  &:focus {
    outline: none;
  }

  span {
    display: block;
    height: 3px;
    width: 100%;
    background-color: #fff;
    transition: transform 0.3s ease-out;
  }

  .top {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(45deg)' : 'rotate(0)')};
  }

  .middle {
    opacity: ${({ isOpen }) => (isOpen ? 0 : 1)};
  }

  .bottom {
    transform: ${({ isOpen }) => (isOpen ? 'rotate(-45deg)' : 'rotate(0)')};
  }

  @media screen and (max-width: 828px) {
    display: flex;
  }
`;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 10;
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  .dropdown {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .dropdown-hamburger-menu li {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:hover {
      background-color: #f2f2f2;
      color: var(--purple);
      z-index: 2;
    }
  }
`;

const NavLinks = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  & a {
    padding: 0.5rem;
  }
`;

const HeartIcon = styled.img`
  height: 20px;
  width: 20px;
`;

const HamburgerMenu = ({
  handleMouseEnter,
  handleMouseLeave,
  showDropdown
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <StyledHamburger isOpen={isOpen} onClick={handleToggle}>
        <span className="top"></span>
        <span className="middle"></span>
        <span className="bottom"></span>
      </StyledHamburger>
      <StyledModal isOpen={isOpen} onClick={handleToggle}>
        <NavLinks>
          <NavLink exact to="/home">
            Home
          </NavLink>
          <NavLink to="/about">About</NavLink>
          {/* <div
            className="dropdown"
            onMouseEnter={() => handleMouseEnter('learn')}
            onMouseLeave={handleMouseLeave}
          > */}
          <NavLink to="/learn">Learn </NavLink>
          {/* {showDropdown === 'learn' && (
              <ul className="dropdown-hamburger-menu">
                <li> */}
          <NavLink to="/learn/faq">FAQ</NavLink>
          {/* </li>
                <li> */}
          <NavLink to="/learn/videos">Videos</NavLink>
          <NavLink to="/learn/library">Library</NavLink>
          {/* </li>
              </ul>
            )}
          </div>

          <div
            className="dropdown"
            onMouseEnter={() => handleMouseEnter('shop')}
            onMouseLeave={handleMouseLeave}
          > */}
          <NavLink to="/shop">Shop </NavLink>
          {/* {showDropdown === 'shop' && (
              <ul className="dropdown-hamburger-menu">
                <li> */}
          <NavLink to="/shop/skulls">Skulls</NavLink>
          {/* </li>
                <li> */}
          <NavLink to="/shop/jewelry">Jewelry</NavLink>
          {/* </li>
              </ul>
            )}
          </div> */}
          <NavLink to="/blog">Blog</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          <NavLink to="/favorites">
            <HeartIcon src={heart} alt="Heart" />
          </NavLink>
          {localStorage.token && (
            <li style={{ display: 'block' }}>
              <NavLink to="/signout">Sign out</NavLink>
            </li>
          )}
          {localStorage.token && (
            <li style={{ display: 'none' }}>
              <NavLink to="/signout">Sign out</NavLink>
            </li>
          )}
          {!localStorage.token && (
            <li style={{ display: 'block' }}>
              <NavLink to="/signin">Sign in</NavLink>
            </li>
          )}
          {!localStorage.token && (
            <li style={{ display: 'none' }}>
              <NavLink to="/signin">Sign in</NavLink>
            </li>
          )}
          <NavLink to="/account">Account</NavLink>
          <NavLink to="/cart">Cart</NavLink>
        </NavLinks>
      </StyledModal>
    </>
  );
};

export default HamburgerMenu;
