import { createGlobalStyle } from 'styled-components';
import chatBotBGCall from '../../src/img/ai-call.png';
import chatBotBGResponse from '../../src/img/ai-response.png';

const GlobalStyles = createGlobalStyle`
  :root {
    --danger: #FF4949;
    --success: #8dc34b;
    --warning: #4d502b;
    --white: #fff;
    --black: #000;
    --grey: #efefef;
    --lightPurple: #6b65ac;
    --yellow: #4d502b;
    --red: #502b3a;
    --green: #2b5041;
    --purple: #2e2b50;
    --yellow: #4d502b;
    --red: #502b3a;
    --bgImage: url('../src/img/gem-bg.png');
    --bgWave: url('../src/img/bg-wave-layered.svg');
    --rightWave: url('../src/img/right-wave-vertical.svg');
    --chatBotBG: url('../src/img/ai-call.png');
  }

  html {
    background-color: #000;
  }

  body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.5rem;
    background-color: #000;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--green);
    color: white;
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 2px;
    cursor: pointer;
    --cast: 2px;
    box-shadow: var(--cast) var(--cast) 0 var(--grey);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
  }

  h1, h2 {
    font-size: 5rem;
    font-family: 'GreatVibes' !important;
    font-weight: 400;
    color: var(--white);
    /* animation: fadeIn 0.5s ease-in-out; */
  }

  h3, h4, h5 {
    font-size: 1rem;
    font-family: 'Montserrat';
    font-weight: 600;
  }

  h6 {
    color: var(--white);
    font-size: 1rem;
    font-family: 'Montserrat';
    font-weight: 600;
  }

  .modal-title {
    font-family: 'Montserrat';
  }

  a {
    font-family: 'Montserrat';
    font-weight: normal;
    text-decoration: none;
    transition: color 0.3s ease-in-out;
  }

  a:hover {
    color: var(--green);
  }

  p a {
    text-decoration: none;
  }

  p {
    line-height: 2;
    font-size: 1.6rem;
    animation: fadeIn 0.5s ease-in-out;
  }

  .form-label {
    font-size: 1.6rem;
    font-family: 'Montserrat';
  }

  .btn-spacer {
    padding: 0 1rem;
  }

  /* Card */

  .hidden {
    display: none;
  }
  

  .show {
    visibility: visible;
  }

  .flip {
    -webkit-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }

  .item-price {
    display: flex;
    justify-content: center;
    padding-bottom: 1rem;
  }

  .signin-form {
    font-size: 2rem;
  }

  .App {
    text-align: center;
    background-color: #000;
    color: #fff;
    height: 100%;
    width: 100%;
    padding-top: 15vh;
    /* animation: fadeIn 0.5s ease-in-out; */
  }

  .app-container {
    max-width: 1024px;
    margin: auto;
    overflow: hidden;
    z-index: 2;
  }

  .title-text {
    font-size: 3.5rem;
    background-color: none;
    /* animation: fadeIn 1.5s ease-in-out; */
  }

  .canvas-container {
    width: 100%;
    height: 60vh;
    display: flex;
    flex-direction: column;
    place-items: center;
    margin: auto;
    z-index: 2;
  }

  #canvas {
    height: 500px;
    margin: 2rem 0 1rem 0;
    touch-action: auto;
    z-index: 2;
  }


 /* enter page */
  
 .visible{
  visibility: visible;
 }

 .hidden{
  visibility: hidden;
 }

 #preloader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 50vh;
    margin-top: 50vh;
    /* z-index: -1; */
}
#loader {
  /* z-index: -1; */
    display: block;
    position: relative;
    left: 50%;
    top: 50%;
    width: 150px;
    height: 150px;
    margin: -175px 0 0 -75px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #9370DB;
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}
#loader:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #BA55D3;
    -webkit-animation: spin 3s linear infinite;
    animation: spin 3s linear infinite;
}
#loader:after {
    content: "";
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border-radius: 50%;
    border: 3px solid transparent;
    border-top-color: #FF00FF;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
}
@-webkit-keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
@keyframes spin {
    0%   {
        -webkit-transform: rotate(0deg);
        -ms-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

  
  

  .skull-img {
    max-width: 25%;
    transition: transform 2.2s;
    margin: auto;
    padding: 2rem 0;
    margin-bottom: 25%;
  }

  .skull-img:hover {
    transform: rotate3d(1, 1, 1, 360deg);
  }

// heart icons on cards
  .heart-icon{
    border: none !important;
    
  }
//forgot email
  .form-email{
    margin-top: -1rem;
    margin-bottom: 2rem;
    padding: .5rem;
  }

  .btn-enter {
    margin-top: -10rem;
    font-size: 5rem;
    font-family: 'Montserrat';
    font-weight: 800;
    
  }

  .btn-form {
    display: flex;
    margin: auto;
    padding: 2rem;
    justify-content: space-evenly;
    font-family: 'Montserrat';
  }

  .bth-flat {
    
    font-family: 'Montserrat';
  }

 /* shopping car quantity */
  .quality-input{

    display: none;  
  }


  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

// Chat Feature 

.chat-container {
  display: flex;
  flex-direction: column;
    margin: auto;
    padding-bottom: 1rem;
   
    background-image: url(${chatBotBGCall});
    background-position: center;
    background-repeat: none;
    background-size: cover;
    transition: all 1.8s ease;
    
    max-width: 300px;
    
    border-radius: 10px;

    button{
      width: 50%;
      background-color: transparent;
      padding: .5rem;
      margin: auto;
      margin-top: -.7rem;
      border: solid 1px white;
     

      &:hover{
        background-color: transparent;
        filter: drop-shadow(0px 8px 18px rgba(255, 255, 255, 1));

      }
    }
}
.chat-container-response {
  display: flex;
  flex-direction: column;
    margin: auto;
    padding-bottom: 1rem;
   
  
    background-image: url(${chatBotBGResponse});
    background-position: center;
    background-repeat: none;
    background-size: cover;
    transition: all 1.8s ease;
   
    max-width: 300px;
  
    border-radius: 10px;

    button{
      width: 50%;
      background-color: transparent;
      padding: .5rem;
      margin: auto;
      margin-top: -.7rem;
      border: solid 1px white;
     

      &:hover{
        background-color: transparent;
        filter: drop-shadow(0px 8px 18px rgba(255, 255, 255, 1));

      }
    }
}

/* =================
      Setup 
     ================= */

/* .setup-inner {
    display: flex;
    justify-content: space-around;
    padding: 1.5em .5em;
    min-height: 234px;
}

.setup-inner>img {
    width: 40%;
    filter: drop-shadow(3px 2px 3px var(--medium-dark));
    align-self: center;
}

.setup-input-container {
    min-height: 74px;
    background-color: var(--white)
} */

.setup-input-container {
  
    padding: 2rem;
    background-color: rgba(255, 255, 255, 0.7);
} 

#ai-text {
  background-color: rgba(255, 255, 255, 0.7); 
  margin: 2rem;
  padding: 2rem;
  border-radius: 10px;
  font-family: 'Montserrat';
  height: 200px;
  overflow: scroll;
  cursor: pointer;
  
}

.hidden-text {
    visibility: hidden;
  }


/* .speech-bubble-ai {
    max-width: 55%;
    min-height: 124px;
    border-radius: var(--border-rad-lg);
    position: relative;
    margin: 0;
    border: 3px solid var(--medium-dark);
    background-color: var(--white);
    align-self: flex-start;
    display: flex;
    align-items: center;
    font-family: 'Montserrat';
}

.speech-bubble-ai:before {
    content: "";
    position: absolute;
    border-left: 9px solid transparent;
    border-right: 9px solid var(--medium-dark);
    border-top: 9px solid var(--medium-dark);
    border-bottom: 9px solid transparent;
    left: -21px;
    top: 64px;
}

.speech-bubble-ai:after {
    content: "";
    position: absolute;
    border-left: 7px solid transparent;
    border-right: 7px solid var(--white);
    border-top: 7px solid var(--white);
    border-bottom: 7px solid transparent;
    left: -11px;
    top: 68px;
}

.speech-bubble-ai>p {
    padding: 0 1.3em;
    color: var(--dark);
    font-size: 85%;
} */

textarea {
  /* max-width: 400px; */
  height: 100px;
  overflow: scroll;
  width: 80%;
  background-color: rgba(255, 255, 255, 0.7);
    /* padding: .8em; */
    margin: 2rem auto;
    border-radius: 10px;
    
    /* min-height: 40px; */
    font-family: 'Montserrat';
}

textarea::placeholder {
    color: var(--black);
    font-size: 88%;
    opacity: 0.8;
}

/* larger mobiles+ */

@media(min-width: 380px) {
    .setup-input-container {
        padding-top: 0;
    }

    .speech-bubble-ai:before {
        top: 92px;
    }

    .speech-bubble-ai:after {
        top: 96px;
    }

    .speech-bubble-ai>p {
        font-size: 100%;
    }

    textarea::placeholder {
        font-size: 100%;
        opacity: 0.8;
    }
}

/* =================
      Buttons & SVG 
     ================= */

     .chat-container button{
      margin-bottom: 2rem;
     }


button {
    border: none;
    background: var(--green);
    cursor: pointer;
    box-shadow: none;
    border-radius: 10px;
}

button:hover {
    background-color: var(--red);
}

.send-btn {
    border-top-right-radius: var(--green);
    border-bottom-right-radius: var(--purple);
    min-width: 50px;
}

.send-btn>img {
    width: 1.6em;
    vertical-align: middle;
}

.view-pitch-btn {
    color: var(--purple);
    border-radius: var(--border-rad-lg);
    padding: 1em;
    margin: .4em auto;
    display: block;
    font-size: 1.2em;
}

.view-pitch-btn:hover {
    box-shadow: 1px 1px 5px 1px var(--medium-dark);
}

img.loading {
    max-width: 40px;
    filter: none;
}

/* =================
      Output 
     ================= */

.output-container {
    display: none;
    flex-direction: column;
    margin: 1em auto;
    color: var(--white);
    padding: 1em;
}

.output-img-container>img {
    max-width: 100%;
    border-radius: var(--border-rad-lg);
    box-shadow: 1px 1px 5px 1px var(--dark);
}

/* =================
      Footer 
     ================= */

footer {
    background-color: var(--dark);
    color: var(--light);
    padding: .8em;
    font-size: .7em;
    text-align: center
}


/* fade in on scroll */

.scroll-text{
    padding-top: 10rem;
  }
  
  .hidden {
    opacity: 0;
    filter: blur(5px);
    transition: all 2s;
   
  }

  .show {
    opacity: 1;
    filter: blur(0);
    transition: all 2s;
  }


`;

export default GlobalStyles;
