import Layout from '../components/Layout';
import ContactForm from '../components/contact/ContactForm.js';

export default function Contact() {
  return (
    <>
      <Layout>
        <>
          <ContactForm></ContactForm>
        </>
      </Layout>
    </>
  );
}
