import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 2rem 0 6rem 0;
  padding: 2rem;
  background-color: var(--purple);

  /* Clip Mask */
  --size: 50px;
  --p: 25px;
  --R: 55.9px /*sqrt(var(--size)*var(--size) + var(--p)*var(--p))*/;

  height: 100%;
  margin: 10px 0;
  padding-top: 6rem;

  -webkit-mask: radial-gradient(
        var(--R) at left 50% var(--d, top) calc(var(--size) + var(--p)),
        #000 99%,
        #0000 101%
      )
      calc(50% - 2 * var(--size)) 0 / calc(4 * var(--size)) 100%,
    radial-gradient(
        var(--R) at left 50% var(--d, top) calc(-1 * var(--p)),
        #0000 99%,
        #000 101%
      )
      left 50% var(--d, top) var(--size) / calc(4 * var(--size)) 100% repeat-x;
  background: linear-gradient(90deg, var(--purple), var(--red));
  border: none;

  /* --d: bottom; */
`;

const LeftColumn = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const RightColumn = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const H1 = styled.h1`
  font-size: 5rem;
  margin-bottom: 1rem;
  padding: 8rem 2rem 0 2rem;
`;

const Subheading = styled.h3`
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: var(--white);
  filter: drop-shadow(0px 8px 18px rgba(255, 255, 255, 0.1));
  background-color: var(--green);
  border-radius: 10px;
  padding: 0.3rem;
`;

const Paragraph = styled.p`
  font-size: 1.1rem;
  line-height: 1.5;
  color: var(--white);
`;

const TwoColumnSection = () => {
  return (
    <>
      <H1 id="get-started">
        Discover the Power <br></br>
        of Crystal Skulls
      </H1>
      <Wrapper>
        <LeftColumn>
          <Subheading>Our Mission</Subheading>
          <Paragraph>
            At Rock Solid Skulls, ancient artifacts meet modern wellbeing. We
            are passionate about the science and spiritual properties of crystal
            skulls and believe in their potential to promote healing,
            meditation, and growth.
          </Paragraph>
          <Subheading>Your Journey </Subheading>
          <Paragraph>
            We understand that each person’s journey is unique. That's why we
            offer a wide selection of carefully sourced crystal skulls and
            jewelry that cater to your individual needs. Our commitment to
            providing reliable expert knowledge and resources makes navigating
            the world of crystal skulls effortless and enjoyable.
          </Paragraph>
        </LeftColumn>
        <RightColumn>
          <Subheading>Community Focused</Subheading>
          <Paragraph>
            Join our community to discover a supportive and inspiring place to
            learn. We provide a space where individuals can explore this
            fascinating world without judgement - built on empathy, wisdom, and
            respect.
          </Paragraph>
          <Subheading>Giving Back</Subheading>
          <Paragraph>
            We believe in using our platform to make a positive impact and are
            committed to supporting causes that align with our values. With
            every purchase made from Rock Solid Skulls, a portion of the
            proceeds goes to support our giving back program. We're proud to be
            making a difference and know that our community enjoys leaving a
            lasting impression along with us.
          </Paragraph>
        </RightColumn>
      </Wrapper>
    </>
  );
};

export default TwoColumnSection;
