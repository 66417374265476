import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import Card from '../components/Card';
import CardFront from '../components/CardFront';
import CardBack from '../components/CardBack';
import Modal from '../components/Modal';
import heartEmpty from '../img/heart-empty.svg';
import heartFilled from '../img/heart-filled.svg';
import loadingSkull from '../img/skull-loading.gif';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  justify-content: space-between;
  max-width: 900px;
  margin: 4rem auto;

  @media (max-width: 768px) {
    justify-content: center;
  }

  @media (max-width: 425px) {
    justify-content: center;
  }
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

const Container = styled.div`
  margin: auto 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: 425px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
const HeartIcon = styled.img`
  display: flex;
  align-self: center;
  height: 25px;
  width: 25px;
  margin: auto;
  padding-top: 1rem;
  cursor: pointer;
`;

const Dropdown = styled.select`
  padding: 0.5rem 1rem;
  font-size: 1.6rem;
  border: none;
  border-radius: 4px;
  background-color: #fff;
  color: #333;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  display: flex;
  margin: auto;
  &:hover {
    background-color: #f5f5f5;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px #007bff;
  }
`;

const Text = styled.h2`
  font-size: 3rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
`;
const Image = styled.img`
  display: flex;
  margin: auto;
  max-width: 60%;
  height: 60%;
  object-fit: cover;
  padding: 0 20% 2rem;
`;

export default function Skulls() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const [skulls, setSkulls] = useState([]);

  const [showModal, setShowModal] = useState(Array(skulls.length).fill(false));
  const [itemId, setItemId] = useState('');

  // handle hearts/ favorites state
  const [favorites, setFavorites] = useState([]);
  const [heartFilledColor, setHeartFilledColor] = useState([]);

  const [selectedTag, setSelectedTag] = useState('');
  const [tagNames, setTagNames] = useState(['All']);
  const [filteredSkulls, setFilteredSkulls] = useState([]);

  // fetch skulls
  useEffect(() => {
    const fetchSkulls = async () => {
      // console.log('BASE_URL', BASE_URL);
      // console.log('process.env.BASE_URL', BASE_URL);
      const res = await fetch(`${BASE_URL}/api/skulls`);
      const data = await res.json();
      console.log('skull fetch data', data);
      setSkulls(data);

      const allTags = data.flatMap((skull) => skull.tags);
      const uniqueTags = [...new Set(allTags)];
      setTagNames(uniqueTags);
    };

    fetchSkulls();
  }, []);

  const handleButtonClick = (id) => {
    const cardFront = document.getElementById(`cardFront-${id}`);
    const cardBack = document.getElementById(`cardBack-${id}`);
    cardFront.classList.toggle('hidden');
    cardFront.classList.toggle('show');
    cardBack.classList.toggle('hidden');
    cardBack.classList.toggle('show');
  };

  // console.log(favorites, heartFilledColor);
  // console.log(skulls);

  // console.log('outside', itemId);

  // Modal confirmation messages
  const title = 'Confirm';
  const message = 'Are you sure?';

  // Add to Cart flow
  // includes logic to sort the correct card itemId and filteredId

  const addToCart = (id) => {
    const filteredIndex = filteredSkulls.findIndex((skull) => skull.id === id);
    const index = skulls.findIndex((skull) => skull.id === id);
    if (index !== -1 || filteredIndex !== -1) {
      const newShowModal = [...showModal];
      newShowModal[filteredIndex !== -1 ? filteredIndex : index] = true;
      setShowModal(newShowModal);
    }
  };

  const handleConfirm = (id) => {
    const filteredIndex = filteredSkulls.findIndex((skull) => skull.id === id);
    const index = skulls.findIndex((skull) => skull.id === id);
    if (index !== -1 || filteredIndex !== -1) {
      const newShowModal = [...showModal];
      newShowModal[filteredIndex !== -1 ? filteredIndex : index] = false;
      setShowModal(newShowModal);

      // Trigger the event for adding to cart
      setItemId(id);
    }
  };

  const handleCancel = (id) => {
    const filteredIndex = filteredSkulls.findIndex((skull) => skull.id === id);
    const index = skulls.findIndex((skull) => skull.id === id);
    if (index !== -1 || filteredIndex !== -1) {
      const newShowModal = [...showModal];
      newShowModal[filteredIndex !== -1 ? filteredIndex : index] = false;
      setShowModal(newShowModal);
      // Handle cancellation
    }
  };

  // Submit item id to POST in cart
  useEffect(() => {
    const addItemToCart = async () => {
      const token = localStorage.getItem('token');
      const requestOptions = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({ itemId })
      };

      // console.log(requestOptions);
      try {
        const res = await fetch(`${BASE_URL}/cart/add`, requestOptions);
        if (res.status === 400) {
          console.log('You already added this to your cart');
        } else {
          // console.log('POST request successful:', res);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (itemId) {
      addItemToCart();
    }
  }, [itemId]);

  // updates single card heart state
  useEffect(() => {
    setHeartFilledColor(
      skulls.map((skull) => ({ id: skull.id, filled: false }))
    );
  }, [skulls]);

  const handleHeartClick = (id) => {
    setHeartFilledColor((prevColors) => {
      const updatedColors = [...prevColors];
      const index = updatedColors.findIndex((color) => color.id === id);

      if (index !== -1) {
        updatedColors[index].filled = !updatedColors[index].filled;
        // Call the correct function based on the filled state
        if (updatedColors[index].filled) {
          addSkullToFavorites(id);
        } else {
          removeSkullFromFavorites(id);
        }
      }

      return updatedColors;
    });
  };

  const addSkullToFavorites = async (id) => {
    const token = localStorage.getItem('token');
    const requestOptions = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    try {
      if (!token) {
        return;
      }
      const res = await fetch(`${BASE_URL}/heart/skull/${id}`, requestOptions);

      if (res.status === 200) {
        // Skull added to favorites successfully
        const updatedFavorites = [...favorites, id];
        setFavorites(updatedFavorites);
      } else {
        // Handle error case
        console.error('Failed to add skull to favorites');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const removeSkullFromFavorites = async (id) => {
    const token = localStorage.getItem('token');
    const requestOptions = {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    };

    try {
      const res = await fetch(
        `${BASE_URL}/heart/delete/skull/${id}`,
        requestOptions
      );

      if (res.status === 200) {
        // Skull removed from favorites successfully
        const updatedFavorites = favorites.filter((skullId) => skullId !== id);
        setFavorites(updatedFavorites);
      } else {
        // Handle error case
        console.error('Failed to remove skull from favorites');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleTagChange = (event) => {
    setSelectedTag(event.target.value);
    // setSelectedTag(tag);
  };

  // console.log('selectedTag', selectedTag);

  // filter skull by tag name
  useEffect(() => {
    const filterSkullsByTag = (skulls, selectedTag) => {
      if (selectedTag === '') {
        return skulls;
      } else {
        return skulls.filter((skull) => skull.tags.includes(selectedTag));
      }
    };

    const filteredSkulls = filterSkullsByTag(skulls, selectedTag);
    setFilteredSkulls(filteredSkulls);
  }, [skulls, selectedTag]);

  // loading animation
  const LoadingAnimation = () => {
    return (
      <LoadingContainer>
        <Image src={loadingSkull} />
        <Text>Loading...</Text>
      </LoadingContainer>
    );
  };

  const [loading, setLoading] = useState(true);
  const [loadedSkulls, setLoadedSkulls] = useState(0);

  useEffect(() => {
    if (loadedSkulls === skulls.length) {
      setLoading(false);
    }
  }, [loadedSkulls]);

  const handleVideoLoad = () => {
    setLoadedSkulls((prevCount) => prevCount + 1);
  };

  // console.log('filteredSkulls!!!', filteredSkulls);
  return (
    <Layout>
      <div>
        <Title>Skull Shop</Title>
        {loading && <LoadingAnimation />}

        <Dropdown value={selectedTag} onChange={handleTagChange}>
          <option value="">All Skulls</option>
          {tagNames.map((tagName) => (
            <option key={tagName} value={tagName}>
              {tagName}
            </option>
          ))}
        </Dropdown>

        <Wrapper>
          <Container>
            {filteredSkulls.map((skull, index) => (
              <Card key={skull.id}>
                <CardFront className="show" id={`cardFront-${skull.id}`}>
                  <img
                    src={`${BASE_URL}/uploads/${skull.photo}`}
                    alt={skull.name}
                  />
                  <div className="card-body">
                    <h3>{skull.name}</h3>
                  </div>
                  <div className="card-footer">
                    <button onClick={() => handleButtonClick(skull.id)}>
                      Details
                    </button>
                  </div>
                  <HeartIcon
                    className="heart-icon"
                    src={
                      heartFilledColor.find((color) => color.id === skull.id)
                        ?.filled
                        ? heartFilled
                        : heartEmpty
                    }
                    alt="Heart"
                    onClick={() => handleHeartClick(skull.id)}
                  />
                </CardFront>
                <CardBack className="hidden" id={`cardBack-${skull.id}`}>
                  <div className="card-body">
                    {/* <h3>{skull.location.address}</h3> */}
                    <p>{skull.description}</p>
                  </div>
                  <div className="item-price">{skull.tags}</div>

                  <div className="item-price">${skull.price}</div>

                  <div className="card-footer">
                    <button onClick={() => addToCart(skull.id)}>Add</button>
                    {showModal[index] && (
                      <Modal
                        onConfirm={() => handleConfirm(skull.id)}
                        onCancel={() => handleCancel(skull.id)}
                        title={title}
                        message={message}
                      >
                        <button onClick={() => handleConfirm(skull.id)}>
                          Yes
                        </button>
                        <button onClick={() => handleCancel(skull.id)}>
                          No
                        </button>
                      </Modal>
                    )}

                    <div className="btn-spacer"> </div>
                    <button onClick={() => handleButtonClick(skull.id)}>
                      Back
                    </button>
                  </div>
                </CardBack>
              </Card>
            ))}
          </Container>
        </Wrapper>
      </div>
    </Layout>
  );
}

// possible solution for filtering skulls
