import { Switch, Route } from 'react-router-dom';
import GlobalStyles from '../styles/GlobalStyles';
import Header from './Header';
import Enter from '../pages/Enter';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
import SignOut from '../pages/SignOut';
import ResetPassword from '../pages/ResetPassword';
import Home from '../pages/Home';
import About from '../pages/About';
import Learn from '../pages/Learn';
import Chat from '../pages/Chat';
import Library from '../pages/Library';
import Faq from '../pages/Faq';
import Videos from '../pages/Videos';
import Shop from '../pages/Shop';
import Skulls from '../pages/Skulls';
import Jewelry from '../pages/Jewelry';
import Blog from '../pages/Blog';
import SingleBlogPost from '../components/blog/SingleBlogPost';
import Contact from '../pages/Contact';
import Cart from '../pages/Cart';
import Favorites from '../pages/Favorites';
import Account from '../pages/Account';

export default function App() {
  // token garbage collection
  const expirationTime = 60 * 60 * 24000; // 60 * 60 * 1000 = 1 hour

  // Schedule the removal of the token from localStorage after expirationTime
  setTimeout(() => {
    // Remove the JWT token from localStorage
    localStorage.removeItem('token');
  }, expirationTime);

  return (
    <>
      <GlobalStyles />
      <div className="app-container">
        <Header />
        <Switch>
          <Route exact path="/" component={Enter} />
          <Route exact path="/signup" component={SignUp} />
          <Route exact path="/signin" component={SignIn} />
          <Route exact path="/signout" component={SignOut} />
          <Route exact path="/account" component={Account} />
          <Route exact path="/reset/:token" component={ResetPassword} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/about" component={About} />
          <Route exact path="/learn" component={Learn} />
          <Route exact path="/chat" component={Chat} />
          <Route exact path="/learn/library" component={Library} />
          <Route exact path="/learn/faq" component={Faq} />
          <Route exact path="/learn/videos" component={Videos} />
          <Route exact path="/shop" component={Shop} />
          <Route exact path="/shop/skulls" component={Skulls} />
          <Route exact path="/shop/jewelry" component={Jewelry} />
          <Route exact path="/blog" component={Blog} />
          <Route exact path="/blog/:id" component={SingleBlogPost} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/favorites" component={Favorites} />
          <Route exact path="/cart" component={Cart} />
        </Switch>
      </div>
    </>
  );
}
