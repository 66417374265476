import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../img/logo.svg';
import heart from '../img/heart.svg';
import ShoppingCartIcon from './nav/ShoppingCartIcon';
import HamburgerMenu from './nav/HamburgerMenu';

const NavStyles = styled.nav`
  background-color: var(--black);

  .logo {
    height: 100px;
    width: 100px;
    margin: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background-image: url(${logo});
    background-repeat: no-repeat;
    z-index: 1;
  }

  .nav-container {
    margin: 0;
    padding: 0 10px 0 0;
    text-align: center;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-gap: 1rem;
    font-size: 1rem;
    z-index: 1;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    z-index: 2;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 200px;
    padding: 10px;
    background-color: var(--black);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 2;
    list-style: none;
    display: flex;
    flex-direction: column;
    z-index: 2;
  }

  .dropdown-menu li {
    margin-bottom: 10px;
    margin-right: 100px;
    z-index: 2;
  }

  .dropdown-menu a {
    display: block;
    padding: 8px;
    color: var(--white);
    text-decoration: none;
    transition: all 0.3s ease;
    z-index: 2;
  }

  .dropdown-menu a:hover {
    background-color: #f2f2f2;
    color: var(--purple);
    z-index: 2;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: center;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    grid-gap: 1rem;
    font-size: 1rem;
    z-index: 1;
  }
  li {
    text-align: center;
  }
  a {
    z-index: 1;
    font-size: 1rem;
    text-decoration: none;
    display: block;
    color: var(--white);
    &:hover {
      color: var(--green);
    }
    @media (max-width: 800px) {
      font-size: 1rem;
    }
  }
  @media (max-width: 750px) {
    --columns: 4;
    margin-bottom: 2rem;
    border-bottom: 2px solid var(--grey);
    padding-bottom: 2rem;
    ul {
      grid-template-rows: auto auto;
      grid-template-columns: repeat(var(--columns), 1fr);
      justify-items: center;
    }
  }
  @media (max-width: 500px) {
    --columns: 2;
  }

  @media screen and (max-width: 828px) {
    .nav-container {
      display: none;
    }
  }
`;

const HeartIcon = styled.img`
  height: 20px;
  width: 20px;
`;

export default function Nav() {
  const [showDropdown, setShowDropdown] = useState(null);

  function handleMouseEnter(name) {
    setShowDropdown(name);
  }

  function handleMouseLeave() {
    setShowDropdown(null);
  }

  const [isOpen, setIsOpen] = useState(false);

  const handleMenuClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <NavStyles>
        <ul>
          <NavLink to="/home">
            <div className="logo"></div>
          </NavLink>
          <div className="nav-container">
            <li>
              <NavLink to="/home">Home</NavLink>
            </li>
            <li>
              <NavLink to="/about">About</NavLink>
            </li>
            <div
              className="dropdown"
              onMouseEnter={() => handleMouseEnter('learn')}
              onMouseLeave={handleMouseLeave}
            >
              <NavLink to="/learn">Learn ▼</NavLink>
              {showDropdown === 'learn' && (
                <ul className="dropdown-menu">
                  <li>
                    <NavLink to="/learn/faq">FAQ</NavLink>
                  </li>
                  <li>
                    <NavLink to="/learn/videos">Videos</NavLink>
                  </li>
                  <li>
                    <NavLink to="/learn/library">Library</NavLink>
                  </li>
                </ul>
              )}
            </div>
            <li>
              <div
                className="dropdown"
                onMouseEnter={() => handleMouseEnter('shop')}
                onMouseLeave={handleMouseLeave}
              >
                <NavLink to="/shop">Shop ▼</NavLink>
                {showDropdown === 'shop' && (
                  <ul className="dropdown-menu">
                    <li>
                      <NavLink to="/shop/skulls">Skulls</NavLink>
                    </li>
                    <li>
                      <NavLink to="/shop/jewelry">Jewelry</NavLink>
                    </li>
                  </ul>
                )}
              </div>
            </li>
            <li>
              <NavLink to="/blog">Blog</NavLink>
            </li>
            <li>
              <NavLink to="/contact">Contact</NavLink>
            </li>
            <li>
              <NavLink to="/favorites">
                <HeartIcon src={heart} alt="Heart" />
              </NavLink>
            </li>
            {!localStorage.token && (
              <li>
                <NavLink to="/signin">Sign in</NavLink>
              </li>
            )}
            {localStorage.token && (
              <li>
                <NavLink to="/signout">Sign out</NavLink>
              </li>
            )}
            <li>
              <NavLink to="/account">
                Account
              </NavLink>
            </li>
            <li>
              <NavLink to="/cart">
                <ShoppingCartIcon></ShoppingCartIcon>
              </NavLink>
            </li>
          </div>
        </ul>
        <HamburgerMenu
          handleMouseEnter={handleMouseEnter}
          handleMouseLeave={handleMouseLeave}
          showDropdown={showDropdown}
          isOpen={isOpen}
          onClick={handleMenuClick}
        ></HamburgerMenu>
      </NavStyles>
    </>
  );
}
