import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/Layout';
import BlogTemplate from '../components/blog/BlogTemplate';
import SingleBlogPost from '../components/blog/SingleBlogPost';

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

export default function Blog() {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const [posts, setPosts] = useState([]);

  // get all posts from db
  useEffect(() => {
    async function fetchPosts() {
      try {
        const response = await fetch(`${BASE_URL}/blog`);
        const data = await response.json();
        // console.log('blog db return', data);
        setPosts(data);
      } catch (error) {
        console.error('Error fetching blog posts:', error);
      }
    }

    fetchPosts();
  }, []);

  return (
    <>
      <Layout>
        <>
          <Title>Blog</Title>
          <BlogTemplate posts={posts}></BlogTemplate>
          <SingleBlogPost blogPosts={posts}></SingleBlogPost>
        </>
      </Layout>
    </>
  );
}
