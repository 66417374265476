import styled from 'styled-components';
import Layout from '../components/Layout';
import TwoColumnCards from '../components/shop/TwoColumnCards';

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;
export default function Shop() {
  return (
    <>
      <Layout>
        <>
          <Title>Shop</Title>
          <TwoColumnCards></TwoColumnCards>
        </>
      </Layout>
    </>
  );
}
