import React, { useEffect, useState } from 'react';

import FilterColumn from '../components/library/FilterColumn';
import Layout from '../components/Layout';
import styled from 'styled-components';
// import loadingSkull from '../img/skull-loading.gif';
import Spinner from '../components/utils/Spinner';

const Container = styled.div`
  padding: 2rem;
  margin: auto;

  @media screen and (max-width: 696px) {
    padding: 1rem;
    margin: auto;
  }
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;

  @media screen and (max-width: 696px) {
    font-size: 4.5rem;
    margin: auto;
    margin-bottom: 1rem;
  }
`;

const Text = styled.h2`
  font-size: 3rem;
  color: #fff;
  font-weight: 400;
  text-align: center;
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  place-items: center;
  margin: auto;
  width: 100%;
  height: 100%;
`;
const Image = styled.img`
  display: flex;
  margin: auto;
  max-width: 60%;
  height: 60%;
  object-fit: cover;
  padding: 0 20% 2rem;
`;

const Library = () => {
  const [loading, setLoading] = useState(true);

  // inverse data from FilterColumn component
  const handleFilterData = (info) => {
    // console.log('inverse info', info);
    if (info) setLoading(false);
  };

  const LoadingAnimation = () => {
    return (
      <LoadingContainer>
        <Spinner />
        <Text>Loading...</Text>
      </LoadingContainer>
    );
  };

  return (
    <>
      <Layout>
        <Container>
          <Title>Library</Title>
          {loading && <LoadingAnimation />}
          <FilterColumn onFilterData={handleFilterData} />
        </Container>
      </Layout>
    </>
  );
};

export default Library;
