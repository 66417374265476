import React, { useState } from 'react';
import styled from 'styled-components';

const FAQWrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
`;

const FAQItem = styled.div`
  margin-bottom: 20px;
`;

const FAQQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #f8f9fa;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #e9ecef;
  }
`;

const FAQQuestionText = styled.h3`
  margin: 0;
`;

const FAQAnswer = styled.div`
  padding: 10px;
  background-color: #f1f3f5;
  border-radius: 5px;
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

const FaqTemplate = ({ faqData }) => {
  const [activeFAQ, setActiveFAQ] = useState(null);

  const handleFAQClick = (faq) => {
    setActiveFAQ(activeFAQ === faq ? null : faq);
  };

  return (
    <>
      <Title>Frequently Asked Questions</Title>
      <FAQWrapper>
        {faqData.map((faq) => (
          <FAQItem key={faq.id}>
            <FAQQuestion onClick={() => handleFAQClick(faq)}>
              <FAQQuestionText>{faq.question}</FAQQuestionText>
              {activeFAQ === faq ? <span>-</span> : <span>+</span>}
            </FAQQuestion>
            {activeFAQ === faq && <FAQAnswer>{faq.answer}</FAQAnswer>}
          </FAQItem>
        ))}
      </FAQWrapper>
    </>
  );
};

export default FaqTemplate;
