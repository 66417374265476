import styled from 'styled-components';
import Layout from '../components/Layout';
import SignOutForm from '../components/signout/SignOutForm';

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 4rem 2rem 4rem;
  text-align: center;
`;

export default function SignOut() {
  return (
    <>
      <Layout>
        <>
          <Title>Sign out</Title>
          <SignOutForm></SignOutForm>
        </>
      </Layout>
    </>
  );
}
