import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout';
import HeroSection from '../components/home/HeroSection';
import TwoColumnSection from '../components/home/TwoColumnSection';
import TwoColumnImageRight from '../components/home/TwoColumnImageRight';
import TwoColumnImageLeft from '../components/home/TwoColumnImageLeft';
import BottomSection from '../components/home/BottomSection';

export default function Home() {


  
  return (
    <Layout>
      <>
        <HeroSection></HeroSection>
        <TwoColumnSection></TwoColumnSection>
        <TwoColumnImageRight></TwoColumnImageRight>
        <TwoColumnImageLeft></TwoColumnImageLeft>
        <BottomSection></BottomSection>
      </>
    </Layout>
  );
}
