import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

const FormWrapper = styled.div`
  max-width: 400px;
  padding: 20px;
  margin: auto;
  border: 1px solid #dcdcdc;
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  font-family: Arial, sans-serif;

  h2 {
    margin-top: 0;
    padding-top: 1rem;
    font-size: 32px;
    font-weight: bold;
    color: #333;
    text-align: center;
  }
`;

const Title = styled.h1`
  font-size: 6rem;
  margin: 5rem 0 2rem 0;
  text-align: center;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 600px;
  margin: 12rem auto 0;
  padding: 2rem;
  /* border: 1px solid #dcdcdc; */
  border-radius: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: transparent;

  @media only screen and (max-width: 325px) {
    flex-direction: column;
    justify-content: space-between;
  }

  .btn-flat {
    margin: 0.5rem;
  }
`;

const UpdateAccountForm = () => {
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: '',
    name: ''
    // Add other fields here for updating account details
  });

  // Add other state variables for handling form submission, validation, etc.

  useEffect(() => {
    // Fetch the current signed-in user's information and update the form state
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/account`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      if (response.ok) {
        const userData = await response.json();
        // Update the form state with the user's data

        console.log(userData);
        setFormData({
          email: userData.email,
          name: userData.name
          // Add other fields here with the respective user data
        });
      } else {
        // Handle the case where fetching user data fails
        console.error('Failed to fetch user data');
      }
    } catch (error) {
      console.error('An error occurred while fetching user data', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${BASE_URL}/api/account/update`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(formData) // Send the updated account data in the request body
      });

      if (response.ok) {
        // Handle successful update here, e.g., show a success message
        console.log('Account updated successfully');
      } else {
        // Handle the case where updating the user's account fails
        console.error('Failed to update account');
      }
    } catch (error) {
      console.error('An error occurred while updating the user account', error);
    }
    alert('Account updated successfully');
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleButtonClick = (value) => {
    if (value === 'signin') {
      history.push('/signin');
    }
    if (value === 'signup') {
      history.push('/signup');
    }
  };

  return (
    <>
<Title>Account</Title>
<style type="text/css">
          {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      font-family: 'Montserrat';
      box-shadow:none;
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem;
      font-size: 1.5rem;
      box-shadow: none;
    }

    `}
        </style>

        {!localStorage.token && (
          <div className="btn-enter">
            <style type="text/css">
              {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      box-shadow:none;
      
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem 2.5rem;
      font-size: 1.5rem;
      
    }

    `}
            </style>
            <ButtonContainer>
              <Button
                variant="flat"
                size="sm"
                value="signin"
                onClick={() => handleButtonClick('signin')}
              >
                Sign in
              </Button>{' '}
              <Button
                variant="flat"
                size="sm"
                value="enter"
                onClick={() => handleButtonClick('signup')}
              >
                Sign up
              </Button>
            </ButtonContainer>
          </div>
        )}

        {localStorage.token && (
          <>
            <FormWrapper>
        <Form className="update-account-form" onSubmit={handleSubmit}>
          {/* Add form fields for updating account details */}
          <Form.Group controlId="formEmail">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="email"
              autoComplete="new-email"
              value={formData.email}
              onChange={handleInputChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formName">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="name"
              name="name"
              autoComplete="new-name"
              placeholder="name"
              value={formData.name}
              onChange={handleInputChange}
              required
            />
          </Form.Group>

          {/* Add other form fields for updating account details */}
          {/* For example: username, full name, address, etc. */}
          <style type="text/css">
            {`
    .btn-flat {
      background-color: var(--green);
      color: white;
      font-family: 'Montserrat';
      box-shadow:none;
    }

    .btn-flat:hover {
      background-color: #502B3A;
      color: white;
    }
    .btn-xxl {
      padding: 1rem;
      font-size: 1.5rem;
      box-shadow: none;
    }

    `}
          </style>
          <div className="btn-form">
            <Button variant="flat" size="md" type="submit">
              Update Account
            </Button>
          </div>
        </Form>
      </FormWrapper>
          </>
        )}
      
      
    </>
  );
};

export default UpdateAccountForm;
