import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import SkullIcon from '../../img/skull-chat-icon.svg';
import Form from 'react-bootstrap/Form';
import chatBotBGCall from '../../img/ai-call.png';
import chatBotBGResponse from '../../img/ai-response.png';

const TalkButton = styled.button`
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 9999;
  background-color: transparent;
  &:hover {
    background-color: transparent;
  }
`;

const ModalWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 20px;
`;

function AI_HANDLER() {
  const API_KEY = process.env.REACT_APP_CHAT_BOT;

  //handle the form honeypot state
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // handle resetting the form bg when the user types
  const handleTextInputChange = (e) => {
    if (e.target.value) {
      setChatContainerClass('chat-container');
    }
  };

  const url = 'https://api.openai.com/v1/completions';
  // const apiKey = API_KEY;
  const [showModal, setShowModal] = useState(false);
  const [prompt, setPrompt] = useState('');
  const [placeholderText, setPlaceholderText] = useState(
    'Ask me a question...'
  );
  const [aiText, setAiText] = useState('');
  const [formData, setFormData] = useState({
    honeypot: ''
  });
  const [chatContainerClass, setChatContainerClass] =
    useState('chat-container');

  const [aITextWindow, setAITextWindow] = useState('hidden-text');

  // console.log('aITextWindow', aITextWindow);

  function fetchBotReply() {
    // Check for honeypot
    if (formData.honeypot) {
      return;
    }

    // logic gate for ai card bg image
    if (prompt) {
      setChatContainerClass('chat-container-response');
    }

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${API_KEY}`
      },
      body: JSON.stringify({
        model: 'text-davinci-003',
        prompt: `Generate a short message to "${prompt}" Mention part of the prompt in your response. Respond with the best answers you can while adding very subtle hints about crystal skulls, gemstones, metaphysical and spiritual beliefs, mindfulness and positivity. If the question is not about crystal skulls, gemstones, metaphysical and spiritual beliefs, mindfulness and positivity, then do not mention crystal skulls,gemstones, metaphysical and spiritual beliefs, mindfulness and positivity in your response. Always use a supportive, reassuring tone. Make your responses less than 25 words and always stop at the end of a sentence with punctuation."`,
        max_tokens: 50
      })
    })
      .then((response) => response.json())
      .then((data) => setAiText(data.choices[0].text));
  }

  const handleSendClick = () => {
    if (!prompt) {
      setAiText('');
    }

    if (prompt) {
      setAITextWindow('show');
      setAiText('Thinking...');
      fetchBotReply();
    }
  };

  const handleModalToggle = () => {
    setAITextWindow('hidden-text');
    setPlaceholderText('Ask me a question...');
    setAiText('');
    setChatContainerClass('chat-container');
    setShowModal(!showModal);
  };

  return (
    <>
      <TalkButton onClick={handleModalToggle}>
        <img src={SkullIcon} alt={'chat'} />
      </TalkButton>
      <Modal show={showModal} onHide={handleModalToggle}>
        <ModalWrapper style={{ backgroundColor: 'transparent' }}>
          <div className={chatContainerClass}>
            <button
              onClick={handleModalToggle}
              style={{
                backgroundColor: 'transparent',
                border: 'none',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginRight: '1.5%',
                marginBottom: '-10%'
              }}
            >
              x
            </button>
            <span id="ai-text" className={aITextWindow} placeholder="">
              {aiText}
            </span>

            <textarea
              id="setup-textarea"
              placeholder={placeholderText}
              value={prompt}
              onChange={(e) => setPrompt(e.target.value)}
              onClick={handleTextInputChange}
            />
            <div id="setup-input-container" />
            <button id="send-btn" onClick={handleSendClick}>
              Send
            </button>
          </div>
          <Form.Group controlId="formHoneypot" style={{ display: 'none' }}>
            <Form.Control
              type="text"
              name="honeypot"
              value={formData.honeypot}
              onChange={handleInputChange}
            />
          </Form.Group>
        </ModalWrapper>
      </Modal>
    </>
  );
}

export default AI_HANDLER;
